import { useMessage } from "../../context";
import SIDRegister from "@web3-name-sdk/register";
import { providers } from "ethers";
import {FormEventHandler, useEffect, useState} from "react";
import Label from "../Label";
import classes from "./ChainIdReg.module.css"
import { formatEther } from "ethers/lib/utils";
import { MessageStatus } from "../../types";

enum ButtonStatus {
  null = "null",
  loading = "loading",
}

const ChainIdReg = () => {

  const [data, setData] = useState({name:"", year:""});
  const [isAvailable, setIsAvailable] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [price, setPrice] = useState<string | number>(0)
  const [valid, setValid] = useState<boolean>(true)
  const [status, setStatus] = useState<ButtonStatus>(ButtonStatus.null)
  const message = useMessage()

  useEffect(() => {
    data.name.length > 2 && setIsLoading(true)
    if (data.name.length < 3) {
      setIsAvailable("") 
      setIsLoading(false)
    }
    if (data.name.length > 0 && !data.name.match(/^[a-z0-9]+$/i)){
      setValid(false)
    }else{
      setValid(true)
      let timerOut = setTimeout(() => {
        if(data.name.length >=3){
          checkPriceAndAvailabilty(data.name)
        }
      },800)
      return () => clearTimeout(timerOut)
  }
  }, [data.name])

  useEffect(() => {
    let timerOut = setTimeout(() => {
      if(parseInt(data.year) > 0 && data.name.length >= 3 && data.name.match(/^[a-z0-9]+$/i)){
        checkPriceAndAvailabilty(data.name, parseInt(data.year))
      }else{ setPrice(0)}
    },800)
    return () => clearTimeout(timerOut)
  }, [ data.year, data.name])

  async function checkPriceAndAvailabilty(name: String, year: number = 0) {
    // detect provider
    if (window.ethereum) {
      const provider = new providers.Web3Provider(window.ethereum)
      // switch to bsc
      await provider.send('wallet_switchEthereumChain', [{ chainId: '0x38' }])
      // connect wallet
      await provider.send('eth_requestAccounts', [])
      // get signer
      const signer = provider.getSigner()
      // init SIDRegister
      const register = new (SIDRegister as any)({signer, chainId: 56});

      // check if available
      const available = await register.getAvailable(name.toLowerCase())
      
      available? setIsAvailable(true): setIsAvailable(false)
      setIsLoading(false)

      // get price. params:{label, duration}
      if(available){
        if(year > 0) {
          const price = await register.getRentPrice(name.toLowerCase(), year)
          setPrice(parseFloat(formatEther(price)).toFixed(3))
        }
      }
      
    }
  }

  async function registerDomain(name: String, year: number) {
    // detect provider
    if (window.ethereum) {
      const provider = new providers.Web3Provider(window.ethereum)
      // switch to bsc
      await provider.send('wallet_switchEthereumChain', [{ chainId: '0x38' }])
      // connect wallet
      await provider.send('eth_requestAccounts', [])
      // get signer
      const signer = provider.getSigner()
      // get address
      const address = await signer.getAddress()
      // init SIDRegister
      const register = new (SIDRegister as any)({ signer, chainId: 56 })
  
      await register.register(name.toLowerCase(), address, year, {
        setPrimaryName: true // set as primary name, default is false,
        // referrer: 'test.bnb' // referrer domain, default is null
      })
    }
  }

  const handleSubmit: FormEventHandler = (event) => {

    setStatus(ButtonStatus.loading)
    event?.preventDefault()
    registerDomain(data.name, parseInt(data.year))
    .then(()=> {
      message("Space Id registration successful", MessageStatus.Success, 5000)
      setStatus(ButtonStatus.null)
      setData({name:"", year:""})
      setPrice(0)
    })
    .catch((error)=> {
      if (error.code === -32603){
        console.log(error.code)
        message("Insufficient Funds", MessageStatus.Error,5000)
        setStatus(ButtonStatus.null)
      }else{
        message("Something went wrong", MessageStatus.Error,3000)
        setStatus(ButtonStatus.null)
      }
    })
    
  }

  return(
    <div className={classes.container}>
      <h2>SPACE ID Registration</h2>
      <form onSubmit={handleSubmit}>
        <Label htmlFor="name" label="Name"/>
        <div className={`${classes.searchInput} bg-black-250 border-radius-8 border-none `}>
          <input 
            onChange={(event) => setData({...data, name: event.target.value})
          }
            name="name"
            placeholder="Search for a name"
            value={data.name}
            className="display-block bg-black-250 width-full border-none border-radius-8 p-y-8 p-x-12 body-s text-black-600"
          />
          {valid?
            (isLoading ?
              <span className={classes.loading}>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                  stroke='currentColor'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  className='feather feather-loader'>
                  <line x1='12' y1='2' x2='12' y2='6'></line>
                  <line x1='12' y1='18' x2='12' y2='22'></line>
                  <line x1='4.93' y1='4.93' x2='7.76' y2='7.76'></line>
                  <line x1='16.24' y1='16.24' x2='19.07' y2='19.07'></line>
                  <line x1='2' y1='12' x2='6' y2='12'></line>
                  <line x1='18' y1='12' x2='22' y2='12'></line>
                  <line x1='4.93' y1='19.07' x2='7.76' y2='16.24'></line>
                  <line x1='16.24' y1='7.76' x2='19.07' y2='4.93'></line>
                </svg>
              </span>
            :
              <div>
                {data.name.length >=3  &&isAvailable === true && <p className={classes.available}>Available</p>}
                {data.name.length >=3 &&isAvailable === false && <p className={classes.registered}>Registered</p>}
              </div>)
                :
              <p className={classes.invalid}>Invalid</p>
          }
          
        </div>
        <Label htmlFor="years" label="Years"/>
        <input 
          type="number" 
          name="years"
          placeholder="Years"
          className={`display-block width-full border-none border-radius-8 bg-black-250 p-y-8 p-x-24 body-s text-black-600`}
          onChange={(event) => setData({...data, year: event.target.value})}
          value={data.year}
        />
        <p className={classes.price}>Registration Fee: <span>{price}</span> bnb</p>
        <button className="btn btn-primary" disabled={valid && isAvailable && price && status === ButtonStatus.null? false : true}>
          {status === ButtonStatus.loading ? 
          <>
            <span className={classes.loading}>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
                stroke='currentColor'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
                className='feather feather-loader'>
                <line x1='12' y1='2' x2='12' y2='6'></line>
                <line x1='12' y1='18' x2='12' y2='22'></line>
                <line x1='4.93' y1='4.93' x2='7.76' y2='7.76'></line>
                <line x1='16.24' y1='16.24' x2='19.07' y2='19.07'></line>
                <line x1='2' y1='12' x2='6' y2='12'></line>
                <line x1='18' y1='12' x2='22' y2='12'></line>
                <line x1='4.93' y1='19.07' x2='7.76' y2='16.24'></line>
                <line x1='16.24' y1='7.76' x2='19.07' y2='4.93'></line>
              </svg>
            </span>
            <span>Registering</span>
          </>:
          "Register"
          }
        </button>
      </form>
    </div>
  )
}

export default ChainIdReg