type Props = {
  htmlFor: string;
  className?: string;
  label: string;
  required?: boolean;
};

const Label = (props: Props): JSX.Element => {
  const { htmlFor, className, label, required } = props;

  return (
    <label
      className={`display-block m-bottom-8 h-xs text-black-500 capitalize ${className}`}
      htmlFor={htmlFor}>
      {label}
      {required && <span className='text-red-200'>*</span>}
    </label>
  );
};

export default Label;
