import { useState } from 'react';

import './VerticalNavigation.css';

type Props = {
  tabs: string[];
};

const VerticalNavigation = (props: Props): JSX.Element => {
  const { tabs } = props;
  const [toggle, setToggle] = useState<Boolean>(true);

  const onPress = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    const target = window.document.getElementById(
      e.currentTarget.href.split('#')[1]
    );
    if (target) {
      var headerOffset = 90;
      var elementPosition = target.getBoundingClientRect().top;
      var offsetPosition = elementPosition - headerOffset;

      window.scrollBy({
        top: offsetPosition,
        behavior: 'smooth',
      });
    }
  };

  return (
    <div className='width-max-content border-radius-8 bg-black-100 shadow-around p-16'>
      <div
        className={`flex justify-space-between align-center col-gap-64 ${
          toggle && 'm-bottom-16'
        }`}>
        <span className={`body-s text-black-600 tab-header`}>
          Call Contract
        </span>
        <button
          onClick={() => setToggle(!toggle)}
          className='p-6 flex justify-center align-center border-none bg-black-100 text-black-500'>
          {toggle ? (
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'
              stroke='currentColor'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
              className='feather feather-chevron-up'>
              <polyline points='18 15 12 9 6 15'></polyline>
            </svg>
          ) : (
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'
              stroke='currentColor'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
              className='feather feather-chevron-down'>
              <polyline points='6 9 12 15 18 9'></polyline>
            </svg>
          )}
        </button>
      </div>
      {toggle && (
        <nav className='border-radius-8 bg-black-250 m-0 p-x-0 p-y-8'>
          {tabs.map((tab, index) => (
            <a
              onClick={onPress}
              data-to-scrollspy-id={tab}
              href={`#${tab}`}
              key={tab}
              className={`scroll-spy-item p-left-8 display-block bg-black-250 text-left capitalize ${
                tabs.length - 1 !== index && 'm-bottom-16'
              }`}>
              {tab.replaceAll("_", " ")}
            </a>
          ))}
        </nav>
      )}
    </div>
  );
};

export default VerticalNavigation;
