import React, { useContext, useState } from 'react';
import Message from '../components/Message';
import { MessageStatus } from '../types';

type MessageFunction = (
  messageText: string,
  status: MessageStatus,
  time?: number,
  callback?: (...arg: any) => any
) => void;

type ContextType = {
  message: MessageFunction;
};

const Value: ContextType = {
  message: (messageText: string, status: MessageStatus) => undefined,
};

const MessageContext = React.createContext<ContextType>(Value);

const MessageProvider = ({
  children,
}: {
  children: JSX.Element;
}): JSX.Element => {
  const [display, setDisplay] = useState<boolean>(false);
  const [messageText, setMessageText] = useState<string>('');
  const [status, setStatus] = useState<MessageStatus>(MessageStatus.Process);

  const message = (
    messageText: string,
    status: MessageStatus,
    time?: number,
    callback?: (...arg: any) => any
  ) => {
    if (messageText && status) {
      setMessageText(messageText);
      setStatus(status);
      setDisplay(true);
    }

    if (time) {
      setTimeout(() => {
        setDisplay(false);
        if (callback) {
          callback();
        }
      }, time);
    }
  };

  return (
    <MessageContext.Provider
      value={{
        message,
      }}>
      {display && <Message message={messageText} status={status} />}
      {children}
    </MessageContext.Provider>
  );
};

export default MessageProvider;

export const useMessage = () => {
  const { message } = useContext(MessageContext);

  const hook: MessageFunction = message;

  return hook;
};
