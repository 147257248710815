export const DeployERC404={
  "inputs": [
    {
      "internalType": "string",
      "name": "_name",
      "type": "string"
    },
    {
      "internalType": "string",
      "name": "_symbol",
      "type": "string"
    },
    {
      "internalType": "uint256",
      "name": "_totalNativeSupply",
      "type": "uint256"
    },
    {
      "internalType": "address",
      "name": "_owner",
      "type": "address"
    }
  ],
  "name": "deployERC404",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
}