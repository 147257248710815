export const whiteList = {
  inputs: [
    {
      internalType: "address",
      name: "target",
      type: "address",
    },
    {
      internalType: "bool",
      name: "state",
      type: "bool",
    },
  ],
  name: "setWhitelist",
  outputs: [],
  stateMutability: "nonpayable",
  type: "function",
};

export const transferTokens = {
  inputs: [
    {
      internalType: "address",
      name: "to",
      type: "address",
    },
    {
      internalType: "uint256",
      name: "amount",
      type: "uint256",
    },
  ],
  name: "transfer",
  outputs: [
    {
      internalType: "bool",
      name: "",
      type: "bool",
    },
  ],
  stateMutability: "nonpayable",
  type: "function",
};

export const transfer_NFT = {
  inputs: [
    {
      internalType: "address",
      name: "from",
      type: "address",
    },
    {
      internalType: "address",
      name: "to",
      type: "address",
    },
    {
      internalType: "uint256",
      name: "id",
      type: "uint256",
    },
  ],
  name: "safeTransferFrom",
  outputs: [],
  stateMutability: "nonpayable",
  type: "function",
};

export const set_gateway = {
  inputs: [{ internalType: "string", name: "_gateway", type: "string" }],
  name: "setGateway",
  outputs: [],
  stateMutability: "nonpayable",
  type: "function",
};
export const set_token_uri = {
  inputs: [{ internalType: "string", name: "_tokenURI", type: "string" }],
  name: "setTokenURI",
  outputs: [],
  stateMutability: "nonpayable",
  type: "function",
};
