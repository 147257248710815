import { Link } from 'react-router-dom';

type Props = {
  to: string;
};

const BackBtn = (props: Props): JSX.Element => {
  const { to } = props;

  return (
    <Link
      to={to}
      className='width-max-content flex align-center border-bottom border-black body-xs text-black-500 col-gap-16'>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='16'
        height='16'
        viewBox='0 0 24 24'
        fill='none'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
        className='feather feather-chevron-left'>
        <polyline points='15 18 9 12 15 6'></polyline>
      </svg>
      <span>Smart Contracts</span>
    </Link>
  );
};

export default BackBtn;
