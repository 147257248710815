import { Link } from "react-router-dom";

import Logo from "../../images/Express_Dark.svg";
import { useState } from "react";
import Chains from "../Chains";
import { useWallet, useModal } from "../../context";

import "./Navigation.css";
import ChainIdReg from "../ChainIdRegistrationModal";

const Navigation = () => {
  const [activeTab, setActiveTab] = useState<number | null>(null);
  const [toggle, setToggle] = useState<boolean>(false);

  const { login, active, chainId } = useWallet();

  const modal = useModal();

  const tabChange = (tab: number) => {
    if (tab === activeTab) {
      setActiveTab(null);
      return;
    }
    setActiveTab(tab);
  };

  const connectWalletClick = () => {
    setToggle(false);
    login();
  };

  return (
    <header>
      <div className="mobile-nav">
        <div className="nav-head grid">
          <Link to="/" className="logo">
            <img className="white-logo" src={Logo} alt="Express Protocol" />
            <img className="color-logo" src={Logo} alt="Express Protocol" />
          </Link>
          <button
            className="hamburger"
            onClick={() => setToggle((oldVal) => !oldVal)}
            type="button"
          >
            {!toggle ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-menu"
              >
                <line x1="3" y1="12" x2="21" y2="12"></line>
                <line x1="3" y1="6" x2="21" y2="6"></line>
                <line x1="3" y1="18" x2="21" y2="18"></line>
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-x"
              >
                <line x1="18" y1="6" x2="6" y2="18"></line>
                <line x1="6" y1="6" x2="18" y2="18"></line>
              </svg>
            )}
          </button>
        </div>
        <nav className={`navigation ${toggle && "active"}`}>
          <hr />
          <ul className="nav-list">
            <li className="nav-item">
              <button onClick={() => tabChange(0)} aria-pressed="false">
                <span>Developer</span>
                {activeTab === 0 ? (
                  <svg
                    className="chevron chevron-up"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <polyline points="18 15 12 9 6 15"></polyline>
                  </svg>
                ) : (
                  <svg
                    className="chevron chevron-down"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <polyline points="6 9 12 15 18 9"></polyline>
                  </svg>
                )}
              </button>
              <div className={`nav-content ${activeTab === 0 && "active"}`}>
                <div>
                  <h2>Developers</h2>
                  <ul>
                    <li>
                      <a
                        href="https://www.youtube.com/watch?v=PITPHolVeG4"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Intro to Express Protocol
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://docs.expressprotocol.io"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Documentation
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.npmjs.com/package/pandora-express"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Express SDK
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://github.com/Pandora-Finance/Modular-contract"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Modular Contract
                      </a>
                    </li>
                  </ul>
                </div>
                <div>
                  <h2>Build</h2>
                  <ul>
                    <li>
                      <a
                        href="https://docs.expressprotocol.io/guide"
                        target="_blank"
                        rel="noreferrer"
                      >
                        NFT MarketPlace
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://docs.expressprotocol.io/guide1"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Auction Markets
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://docs.expressprotocol.io/guide2"
                        target="_blank"
                        rel="noreferrer"
                      >
                        NFT Drop Dapp
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://docs.expressprotocol.io/guide3"
                        target="_blank"
                        rel="noreferrer"
                      >
                        NFT Ticket Booking Dapp
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://docs.expressprotocol.io/guide4"
                        target="_blank"
                        rel="noreferrer"
                      >
                        NFT Lending and Borrowing Dapp
                      </a>
                    </li>
                  </ul>
                </div>
                <div>
                  <h2>Grants</h2>
                  <dl>
                    <a target="_blank" rel="noreferrer" className="empty-link">
                      <dt>Apply for funding</dt>
                      <dd>Are you building on Express? Apply for a grant</dd>
                    </a>
                  </dl>
                </div>
              </div>
            </li>
            <li className="nav-item">
              <button onClick={() => tabChange(1)} aria-pressed="false">
                <span>Community</span>
                {activeTab === 1 ? (
                  <svg
                    className="chevron chevron-up"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <polyline points="18 15 12 9 6 15"></polyline>
                  </svg>
                ) : (
                  <svg
                    className="chevron chevron-down"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <polyline points="6 9 12 15 18 9"></polyline>
                  </svg>
                )}
              </button>
              <div className={`nav-content ${activeTab === 1 && "active"}`}>
                <div>
                  <h2>Articles</h2>
                  <ul>
                    <li>
                      <a href="/blog.html" target="_blank" rel="noreferrer">
                        Blog
                      </a>
                    </li>
                  </ul>
                </div>
                <div>
                  <h2>Bounties</h2>
                  <dl>
                    <a
                      href="https://medium.com/pandoraprotocol/express-testnet-bug-bounty-program-d09c27931054"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <dt>Express TestNet Bug Bounty Program</dt>
                      <dd>
                        The Express Protocol Testnet is LIVE! With this launch,
                        we have got one step closer to achieving our grand,,,
                      </dd>
                    </a>
                  </dl>
                </div>
                <div>
                  <h2>Socials</h2>
                  <ul className="grid social-list-2">
                    <li className="social-icon">
                      <a
                        href="https://twitter.com/expressprotocol"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M23.6435 4.93561C22.8085 5.30561 21.9115 5.55561 20.9685 5.66861C21.9415 5.0864 22.6694 4.17008 23.0165 3.09061C22.1023 3.6336 21.1018 4.01581 20.0585 4.22061C19.3569 3.47148 18.4276 2.97494 17.4148 2.80809C16.4021 2.64123 15.3626 2.8134 14.4577 3.29786C13.5529 3.78233 12.8333 4.55197 12.4107 5.48732C11.988 6.42266 11.886 7.47136 12.1205 8.47061C10.2682 8.37761 8.45613 7.89616 6.80192 7.05752C5.14772 6.21887 3.68834 5.04177 2.5185 3.60261C2.1185 4.29261 1.8885 5.09261 1.8885 5.94461C1.88805 6.71161 2.07693 7.46685 2.43838 8.14334C2.79982 8.81983 3.32266 9.39665 3.9605 9.82261C3.22078 9.79907 2.49737 9.5992 1.8505 9.23961V9.29961C1.85043 10.3754 2.22253 11.418 2.90368 12.2506C3.58483 13.0832 4.53307 13.6545 5.5875 13.8676C4.90128 14.0533 4.18184 14.0807 3.4835 13.9476C3.781 14.8732 4.3605 15.6826 5.14087 16.2625C5.92124 16.8424 6.86342 17.1638 7.8355 17.1816C6.18533 18.477 4.14739 19.1797 2.0495 19.1766C1.67788 19.1767 1.30658 19.155 0.9375 19.1116C3.06698 20.4808 5.54584 21.2074 8.0775 21.2046C16.6475 21.2046 21.3325 14.1066 21.3325 7.95061C21.3325 7.75061 21.3275 7.54861 21.3185 7.34861C22.2298 6.68959 23.0164 5.87351 23.6415 4.93861L23.6435 4.93561Z" />
                        </svg>
                        <span>Twitter</span>
                      </a>
                    </li>
                    <li className="social-icon">
                      <a
                        href="https://www.youtube.com/channel/UCHmg9KoZEOG91ppA8NA8Zrw/featured"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M22.5396 6.42C22.4208 5.94541 22.1789 5.51057 21.8382 5.15941C21.4976 4.80824 21.0703 4.55318 20.5996 4.42C18.8796 4 11.9996 4 11.9996 4C11.9996 4 5.1196 4 3.3996 4.46C2.92884 4.59318 2.50157 4.84824 2.16094 5.19941C1.82031 5.55057 1.57838 5.98541 1.4596 6.46C1.14481 8.20556 0.990831 9.97631 0.999595 11.75C0.988374 13.537 1.14236 15.3213 1.4596 17.08C1.59055 17.5398 1.8379 17.9581 2.17774 18.2945C2.51758 18.6308 2.93842 18.8738 3.3996 19C5.1196 19.46 11.9996 19.46 11.9996 19.46C11.9996 19.46 18.8796 19.46 20.5996 19C21.0703 18.8668 21.4976 18.6118 21.8382 18.2606C22.1789 17.9094 22.4208 17.4746 22.5396 17C22.852 15.2676 23.0059 13.5103 22.9996 11.75C23.0108 9.96295 22.8568 8.1787 22.5396 6.42Z"
                            fill="currentColor"
                          />
                          <path
                            d="M9.75 15.02L15.5 11.75L9.75 8.47998V15.02Z"
                            fill="white"
                          />
                        </svg>
                        <span>YouTube</span>
                      </a>
                    </li>
                    <li className="social-icon">
                      <a
                        href="https://t.me/PandoraProtocol"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M20.6646 3.71536L2.93461 10.5524C1.72461 11.0384 1.73161 11.7134 2.71261 12.0144L7.26461 13.4344L17.7966 6.78935C18.2946 6.48635 18.7496 6.64935 18.3756 6.98135L9.84261 14.6824H9.84061L9.84261 14.6834L9.52861 19.3754C9.98861 19.3754 10.1916 19.1644 10.4496 18.9154L12.6606 16.7654L17.2596 20.1624C18.1076 20.6294 18.7166 20.3894 18.9276 19.3774L21.9466 5.14936C22.2556 3.91036 21.4736 3.34936 20.6646 3.71536Z" />
                        </svg>
                        <span>Telegram</span>
                      </a>
                    </li>
                    <li className="social-icon">
                      <a
                        href="https://medium.com/pandoraprotocol/expressprotocol/home"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M3 3V21H21V3H3ZM17.9542 7.26525L16.9875 8.19C16.9467 8.22115 16.9152 8.26281 16.8963 8.31051C16.8774 8.3582 16.8719 8.41014 16.8802 8.46075V15.2618C16.8719 15.3124 16.8774 15.3643 16.8963 15.412C16.9152 15.4597 16.9467 15.5013 16.9875 15.5325L17.9303 16.458V16.6605H13.188V16.458L14.1652 15.51C14.2612 15.414 14.2612 15.3855 14.2612 15.24V9.7425L11.5463 16.6395H11.1788L8.0175 9.7425V14.3647C8.00449 14.4606 8.01347 14.5582 8.04374 14.65C8.07402 14.7419 8.1248 14.8257 8.19225 14.895L9.46275 16.4363V16.6388H5.86125V16.4363L7.1325 14.895C7.1994 14.8256 7.24905 14.7415 7.27745 14.6494C7.30585 14.5573 7.3122 14.4598 7.296 14.3647V9.0195C7.30343 8.9464 7.29351 8.87259 7.26706 8.80404C7.2406 8.7355 7.19836 8.67416 7.14375 8.625L6.01425 7.26525V7.062H9.51975L12.2295 13.0043L14.6115 7.06125H17.9535L17.9542 7.26525Z" />
                        </svg>
                        <span>Medium</span>
                      </a>
                    </li>
                    <li className="social-icon">
                      <a
                        href="https://discord.gg/E7k4m4nzyc"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <svg
                          width="24"
                          height="20"
                          viewBox="0 0 24 20"
                          fill="currentColor"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clipPath="url(#clip0_2183_54985)">
                            <path d="M20.317 2.35981C18.7873 1.65793 17.147 1.14081 15.4319 0.844641C15.4007 0.838925 15.3695 0.85321 15.3534 0.88178C15.1424 1.257 14.9087 1.74649 14.7451 2.13124C12.9004 1.85507 11.0652 1.85507 9.25832 2.13124C9.09465 1.73794 8.85248 1.257 8.64057 0.88178C8.62448 0.854163 8.59328 0.839879 8.56205 0.844641C6.84791 1.13987 5.20756 1.65698 3.67694 2.35981C3.66368 2.36552 3.65233 2.37506 3.64479 2.38743C0.533392 7.03579 -0.31895 11.5699 0.0991801 16.0478C0.101072 16.0697 0.11337 16.0906 0.130398 16.104C2.18321 17.6115 4.17171 18.5267 6.12328 19.1333C6.15451 19.1429 6.18761 19.1315 6.20748 19.1057C6.66913 18.4753 7.08064 17.8106 7.43348 17.1115C7.4543 17.0706 7.43442 17.022 7.39186 17.0058C6.73913 16.7582 6.1176 16.4563 5.51973 16.1135C5.47244 16.0859 5.46865 16.0182 5.51216 15.9859C5.63797 15.8916 5.76382 15.7935 5.88395 15.6944C5.90569 15.6764 5.93598 15.6725 5.96153 15.684C9.88928 17.4772 14.1415 17.4772 18.023 15.684C18.0485 15.6716 18.0788 15.6754 18.1015 15.6935C18.2216 15.7925 18.3475 15.8916 18.4742 15.9859C18.5177 16.0182 18.5149 16.0859 18.4676 16.1135C17.8697 16.463 17.2482 16.7582 16.5945 17.0049C16.552 17.0211 16.533 17.0706 16.5538 17.1115C16.9143 17.8096 17.3258 18.4743 17.7789 19.1048C17.7978 19.1315 17.8319 19.1429 17.8631 19.1333C19.8241 18.5267 21.8126 17.6115 23.8654 16.104C23.8834 16.0906 23.8948 16.0706 23.8967 16.0487C24.3971 10.8718 23.0585 6.37488 20.3482 2.38838C20.3416 2.37506 20.3303 2.36552 20.317 2.35981ZM8.02002 13.3212C6.8375 13.3212 5.86313 12.2356 5.86313 10.9023C5.86313 9.56901 6.8186 8.48336 8.02002 8.48336C9.23087 8.48336 10.1958 9.57854 10.1769 10.9023C10.1769 12.2356 9.22141 13.3212 8.02002 13.3212ZM15.9947 13.3212C14.8123 13.3212 13.8379 12.2356 13.8379 10.9023C13.8379 9.56901 14.7933 8.48336 15.9947 8.48336C17.2056 8.48336 18.1705 9.57854 18.1516 10.9023C18.1516 12.2356 17.2056 13.3212 15.9947 13.3212Z" />
                          </g>
                          <defs>
                            <clipPath id="clip0_2183_54985">
                              <rect
                                width="24"
                                height="18.5915"
                                fill="white"
                                transform="translate(0 0.704224)"
                              />
                            </clipPath>
                          </defs>
                        </svg>
                        <span>Discord</span>
                      </a>
                    </li>
                    <li className="social-icon">
                      <a
                        href="https://github.com/Pandora-Finance"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M9.53624 24C9.53624 24 9.40588 23.4585 9.53624 20.6162C8.13973 20.6646 7.2574 20.5672 6.88923 20.3242C6.33705 19.9595 5.78419 18.835 5.29368 18.0425C4.80317 17.2501 3.71444 17.1248 3.27482 16.9476C2.83519 16.7704 2.72483 16.0483 4.48623 16.5944C6.24767 17.1406 6.33354 18.6279 6.88923 18.9757C7.44492 19.3235 8.77334 19.1713 9.37554 18.8927C9.97767 18.6141 9.93339 17.5768 10.0408 17.1659C10.1765 16.7823 9.69801 16.6972 9.68749 16.6941C9.09965 16.6941 6.01141 16.0196 5.16311 13.0165C4.31489 10.0135 5.40759 8.05058 5.99051 7.20678C6.37911 6.64422 6.34473 5.44533 5.88738 3.60997C7.54798 3.39689 8.82942 3.92174 9.73185 5.18466C9.73272 5.19191 10.9149 4.47874 12.7832 4.47874C14.6515 4.47874 15.3143 5.05964 15.8258 5.18466C16.3373 5.30968 16.7465 2.97397 19.9056 3.60997C19.246 4.91175 18.6937 6.53842 19.1153 7.20678C19.5368 7.87513 21.1914 10.0004 20.1751 13.0165C19.4975 15.0273 18.1655 16.2531 16.1792 16.6941C15.9513 16.767 15.8375 16.8848 15.8375 17.0473C15.8375 17.291 16.1454 17.3176 16.5891 18.4594C16.8848 19.2205 16.6531 24 16.6531 24H15.1571H13.1651H11.325H9.53624Z" />
                        </svg>
                        <span>GitHub</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
          </ul>
          <hr />

          <div className="social-media grid">
            <div id="social-head">Follow Us</div>
            <ul className="social-list">
              <li className="social-icon">
                <a
                  href="https://twitter.com/expressprotocol"
                  target="_blank"
                  rel="noreferrer"
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M23.6435 4.93561C22.8085 5.30561 21.9115 5.55561 20.9685 5.66861C21.9415 5.0864 22.6694 4.17008 23.0165 3.09061C22.1023 3.6336 21.1018 4.01581 20.0585 4.22061C19.3569 3.47148 18.4276 2.97494 17.4148 2.80809C16.4021 2.64123 15.3626 2.8134 14.4577 3.29786C13.5529 3.78233 12.8333 4.55197 12.4107 5.48732C11.988 6.42266 11.886 7.47136 12.1205 8.47061C10.2682 8.37761 8.45613 7.89616 6.80192 7.05752C5.14772 6.21887 3.68834 5.04177 2.5185 3.60261C2.1185 4.29261 1.8885 5.09261 1.8885 5.94461C1.88805 6.71161 2.07693 7.46685 2.43838 8.14334C2.79982 8.81983 3.32266 9.39665 3.9605 9.82261C3.22078 9.79907 2.49737 9.5992 1.8505 9.23961V9.29961C1.85043 10.3754 2.22253 11.418 2.90368 12.2506C3.58483 13.0832 4.53307 13.6545 5.5875 13.8676C4.90128 14.0533 4.18184 14.0807 3.4835 13.9476C3.781 14.8732 4.3605 15.6826 5.14087 16.2625C5.92124 16.8424 6.86342 17.1638 7.8355 17.1816C6.18533 18.477 4.14739 19.1797 2.0495 19.1766C1.67788 19.1767 1.30658 19.155 0.9375 19.1116C3.06698 20.4808 5.54584 21.2074 8.0775 21.2046C16.6475 21.2046 21.3325 14.1066 21.3325 7.95061C21.3325 7.75061 21.3275 7.54861 21.3185 7.34861C22.2298 6.68959 23.0164 5.87351 23.6415 4.93861L23.6435 4.93561Z"></path>
                  </svg>
                </a>
              </li>
              <li className="social-icon">
                <a
                  href="https://discord.gg/E7k4m4nzyc"
                  target="_blank"
                  rel="noreferrer"
                >
                  <svg
                    width="24"
                    height="20"
                    viewBox="0 0 24 20"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_2183_54985)">
                      <path d="M20.317 2.35981C18.7873 1.65793 17.147 1.14081 15.4319 0.844641C15.4007 0.838925 15.3695 0.85321 15.3534 0.88178C15.1424 1.257 14.9087 1.74649 14.7451 2.13124C12.9004 1.85507 11.0652 1.85507 9.25832 2.13124C9.09465 1.73794 8.85248 1.257 8.64057 0.88178C8.62448 0.854163 8.59328 0.839879 8.56205 0.844641C6.84791 1.13987 5.20756 1.65698 3.67694 2.35981C3.66368 2.36552 3.65233 2.37506 3.64479 2.38743C0.533392 7.03579 -0.31895 11.5699 0.0991801 16.0478C0.101072 16.0697 0.11337 16.0906 0.130398 16.104C2.18321 17.6115 4.17171 18.5267 6.12328 19.1333C6.15451 19.1429 6.18761 19.1315 6.20748 19.1057C6.66913 18.4753 7.08064 17.8106 7.43348 17.1115C7.4543 17.0706 7.43442 17.022 7.39186 17.0058C6.73913 16.7582 6.1176 16.4563 5.51973 16.1135C5.47244 16.0859 5.46865 16.0182 5.51216 15.9859C5.63797 15.8916 5.76382 15.7935 5.88395 15.6944C5.90569 15.6764 5.93598 15.6725 5.96153 15.684C9.88928 17.4772 14.1415 17.4772 18.023 15.684C18.0485 15.6716 18.0788 15.6754 18.1015 15.6935C18.2216 15.7925 18.3475 15.8916 18.4742 15.9859C18.5177 16.0182 18.5149 16.0859 18.4676 16.1135C17.8697 16.463 17.2482 16.7582 16.5945 17.0049C16.552 17.0211 16.533 17.0706 16.5538 17.1115C16.9143 17.8096 17.3258 18.4743 17.7789 19.1048C17.7978 19.1315 17.8319 19.1429 17.8631 19.1333C19.8241 18.5267 21.8126 17.6115 23.8654 16.104C23.8834 16.0906 23.8948 16.0706 23.8967 16.0487C24.3971 10.8718 23.0585 6.37488 20.3482 2.38838C20.3416 2.37506 20.3303 2.36552 20.317 2.35981ZM8.02002 13.3212C6.8375 13.3212 5.86313 12.2356 5.86313 10.9023C5.86313 9.56901 6.8186 8.48336 8.02002 8.48336C9.23087 8.48336 10.1958 9.57854 10.1769 10.9023C10.1769 12.2356 9.22141 13.3212 8.02002 13.3212ZM15.9947 13.3212C14.8123 13.3212 13.8379 12.2356 13.8379 10.9023C13.8379 9.56901 14.7933 8.48336 15.9947 8.48336C17.2056 8.48336 18.1705 9.57854 18.1516 10.9023C18.1516 12.2356 17.2056 13.3212 15.9947 13.3212Z"></path>
                    </g>
                    <defs>
                      <clipPath id="clip0_2183_54985">
                        <rect
                          width="24"
                          height="18.5915"
                          fill="#ffffffff"
                          transform="translate(0 0.704224)"
                        ></rect>
                      </clipPath>
                    </defs>
                  </svg>
                </a>
              </li>
              <li className="social-icon">
                <a
                  href="https://t.me/PandoraProtocol"
                  target="_blank"
                  rel="noreferrer"
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M20.6646 3.71536L2.93461 10.5524C1.72461 11.0384 1.73161 11.7134 2.71261 12.0144L7.26461 13.4344L17.7966 6.78935C18.2946 6.48635 18.7496 6.64935 18.3756 6.98135L9.84261 14.6824H9.84061L9.84261 14.6834L9.52861 19.3754C9.98861 19.3754 10.1916 19.1644 10.4496 18.9154L12.6606 16.7654L17.2596 20.1624C18.1076 20.6294 18.7166 20.3894 18.9276 19.3774L21.9466 5.14936C22.2556 3.91036 21.4736 3.34936 20.6646 3.71536Z"></path>
                  </svg>
                </a>
              </li>
              <li className="social-icon">
                <a
                  href="https://www.instagram.com/pandora_finance"
                  target="_blank"
                  rel="noreferrer"
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M11.7667 8.64435C10.0464 8.64435 8.64249 10.0483 8.64249 11.7686C8.64249 13.4889 10.0464 14.8928 11.7667 14.8928C13.487 14.8928 14.8909 13.4889 14.8909 11.7686C14.8909 10.0483 13.487 8.64435 11.7667 8.64435ZM21.137 11.7686C21.137 10.4748 21.1487 9.19279 21.0761 7.90138C21.0034 6.40138 20.6612 5.07013 19.5644 3.97326C18.4652 2.87404 17.1362 2.53419 15.6362 2.46154C14.3425 2.38888 13.0605 2.4006 11.7691 2.4006C10.4753 2.4006 9.19328 2.38888 7.90187 2.46154C6.40187 2.53419 5.07062 2.87638 3.97374 3.97326C2.87453 5.07247 2.53468 6.40138 2.46203 7.90138C2.38937 9.19513 2.40109 10.4772 2.40109 11.7686C2.40109 13.06 2.38937 14.3443 2.46203 15.6358C2.53468 17.1358 2.87687 18.467 3.97374 19.5639C5.07296 20.6631 6.40187 21.0029 7.90187 21.0756C9.19562 21.1483 10.4776 21.1365 11.7691 21.1365C13.0628 21.1365 14.3448 21.1483 15.6362 21.0756C17.1362 21.0029 18.4675 20.6608 19.5644 19.5639C20.6636 18.4647 21.0034 17.1358 21.0761 15.6358C21.1511 14.3443 21.137 13.0623 21.137 11.7686ZM11.7667 16.5756C9.10656 16.5756 6.95968 14.4287 6.95968 11.7686C6.95968 9.10841 9.10656 6.96154 11.7667 6.96154C14.4269 6.96154 16.5737 9.10841 16.5737 11.7686C16.5737 14.4287 14.4269 16.5756 11.7667 16.5756ZM16.7706 7.88732C16.1495 7.88732 15.648 7.38576 15.648 6.76466C15.648 6.14357 16.1495 5.64201 16.7706 5.64201C17.3917 5.64201 17.8933 6.14357 17.8933 6.76466C17.8935 6.91214 17.8645 7.05821 17.8082 7.1945C17.7518 7.33079 17.6692 7.45463 17.5649 7.55891C17.4606 7.6632 17.3368 7.74588 17.2005 7.80224C17.0642 7.85859 16.9181 7.8875 16.7706 7.88732Z"></path>
                  </svg>
                </a>
              </li>
              <li className="social-icon">
                <a
                  href="https://github.com/Pandora-Finance"
                  target="_blank"
                  rel="noreferrer"
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M9.53624 24C9.53624 24 9.40588 23.4585 9.53624 20.6162C8.13973 20.6646 7.2574 20.5672 6.88923 20.3242C6.33705 19.9595 5.78419 18.835 5.29368 18.0425C4.80317 17.2501 3.71444 17.1248 3.27482 16.9476C2.83519 16.7704 2.72483 16.0483 4.48623 16.5944C6.24767 17.1406 6.33354 18.6279 6.88923 18.9757C7.44492 19.3235 8.77334 19.1713 9.37554 18.8927C9.97767 18.6141 9.93339 17.5768 10.0408 17.1659C10.1765 16.7823 9.69801 16.6972 9.68749 16.6941C9.09965 16.6941 6.01141 16.0196 5.16311 13.0165C4.31489 10.0135 5.40759 8.05058 5.99051 7.20678C6.37911 6.64422 6.34473 5.44533 5.88738 3.60997C7.54798 3.39689 8.82942 3.92174 9.73185 5.18466C9.73272 5.19191 10.9149 4.47874 12.7832 4.47874C14.6515 4.47874 15.3143 5.05964 15.8258 5.18466C16.3373 5.30968 16.7465 2.97397 19.9056 3.60997C19.246 4.91175 18.6937 6.53842 19.1153 7.20678C19.5368 7.87513 21.1914 10.0004 20.1751 13.0165C19.4975 15.0273 18.1655 16.2531 16.1792 16.6941C15.9513 16.767 15.8375 16.8848 15.8375 17.0473C15.8375 17.291 16.1454 17.3176 16.5891 18.4594C16.8848 19.2205 16.6531 24 16.6531 24H15.1571H13.1651H11.325H9.53624Z" />
                  </svg>
                </a>
              </li>
              <li className="social-icon">
                <a
                  href="https://medium.com/pandoraprotocol/expressprotocol/home"
                  target="_blank"
                  rel="noreferrer"
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M3 3V21H21V3H3ZM17.9542 7.26525L16.9875 8.19C16.9467 8.22115 16.9152 8.26281 16.8963 8.31051C16.8774 8.3582 16.8719 8.41014 16.8802 8.46075V15.2618C16.8719 15.3124 16.8774 15.3643 16.8963 15.412C16.9152 15.4597 16.9467 15.5013 16.9875 15.5325L17.9303 16.458V16.6605H13.188V16.458L14.1652 15.51C14.2612 15.414 14.2612 15.3855 14.2612 15.24V9.7425L11.5463 16.6395H11.1788L8.0175 9.7425V14.3647C8.00449 14.4606 8.01347 14.5582 8.04374 14.65C8.07402 14.7419 8.1248 14.8257 8.19225 14.895L9.46275 16.4363V16.6388H5.86125V16.4363L7.1325 14.895C7.1994 14.8256 7.24905 14.7415 7.27745 14.6494C7.30585 14.5573 7.3122 14.4598 7.296 14.3647V9.0195C7.30343 8.9464 7.29351 8.87259 7.26706 8.80404C7.2406 8.7355 7.19836 8.67416 7.14375 8.625L6.01425 7.26525V7.062H9.51975L12.2295 13.0043L14.6115 7.06125H17.9535L17.9542 7.26525Z" />
                  </svg>
                </a>
              </li>
            </ul>
          </div>
          <hr />
          {active && chainId === 56 && 
            <div className="mobile-btn-container">
              <button 
                className="btn btn-secondary" 
                onClick={()=>{
                  modal(<ChainIdReg />)
                  setToggle(oldVal=> !oldVal)
                  }
                }>
                {/* Chain Id Registration */}
                SPACE ID .bnb domain Registration
              </button>
          </div>}
          <div className="mobile-btn-container">
            {active ? (
              <Chains />
            ) : (
              <button
                onClick={connectWalletClick}
                className="btn btn-secondary"
              >
                Connect Wallet
                <svg
                  width="29"
                  height="28"
                  viewBox="0 0 29 28"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.5117 21L17.5117 14L10.5117 7"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            )}
          </div>
        </nav>
      </div>

      <div className="desktop-nav grid">
        <div className="nav-head">
          <Link to="/" className="logo">
            <img className="white-logo" src={Logo} alt="Express Protocol" />
            <img className="color-logo" src={Logo} alt="Express Protocol" />
          </Link>
        </div>
        <nav className="navigation">
          <ul className="nav-list">
            <li className="nav-item">
              <button onClick={() => tabChange(0)} aria-pressed="false">
                <span>Developer</span>
                {activeTab === 0 ? (
                  <svg
                    className="chevron chevron-up"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <polyline points="18 15 12 9 6 15"></polyline>
                  </svg>
                ) : (
                  <svg
                    className="chevron chevron-down"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <polyline points="6 9 12 15 18 9"></polyline>
                  </svg>
                )}
              </button>
              <div className={`nav-content ${activeTab === 0 && "active"}`}>
                <div>
                  <h2>Developers</h2>
                  <ul>
                    <li>
                      <a
                        href="https://www.youtube.com/watch?v=PITPHolVeG4"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Intro to Express Protocol
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://docs.expressprotocol.io"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Documentation
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.npmjs.com/package/pandora-express"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Express SDK
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://github.com/Pandora-Finance/Modular-contract"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Modular Contract
                      </a>
                    </li>
                  </ul>
                </div>
                <div>
                  <h2>Build</h2>
                  <ul>
                    <li>
                      <a
                        href="https://docs.expressprotocol.io/guide"
                        target="_blank"
                        rel="noreferrer"
                      >
                        NFT MarketPlace
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://docs.expressprotocol.io/guide1"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Auction Markets
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://docs.expressprotocol.io/guide2"
                        target="_blank"
                        rel="noreferrer"
                      >
                        NFT Drop Dapp
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://docs.expressprotocol.io/guide3"
                        target="_blank"
                        rel="noreferrer"
                      >
                        NFT Ticket Booking Dapp
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://docs.expressprotocol.io/guide4"
                        target="_blank"
                        rel="noreferrer"
                      >
                        NFT Lending and Borrowing Dapp
                      </a>
                    </li>
                  </ul>
                </div>
                <div>
                  <h2>Grants</h2>
                  <dl>
                    <a target="_blank" rel="noreferrer" className="empty-link">
                      <dt>Apply for funding</dt>
                      <dd>Are you building on Express? Apply for a grant</dd>
                    </a>
                  </dl>
                </div>
              </div>
            </li>
            <li className="nav-item">
              <button onClick={() => tabChange(1)} aria-pressed="false">
                <span>Community</span>
                {activeTab === 1 ? (
                  <svg
                    className="chevron chevron-up"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <polyline points="18 15 12 9 6 15"></polyline>
                  </svg>
                ) : (
                  <svg
                    className="chevron chevron-down"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <polyline points="6 9 12 15 18 9"></polyline>
                  </svg>
                )}
              </button>
              <div className={`nav-content ${activeTab === 1 && "active"}`}>
                <div>
                  <h2>Articles</h2>
                  <ul>
                    <li>
                      <a href="/blog.html" target="_blank" rel="noreferrer">
                        Blog
                      </a>
                    </li>
                  </ul>
                </div>
                <div>
                  <h2>Bounties</h2>
                  <dl>
                    <a
                      href="https://medium.com/pandoraprotocol/express-testnet-bug-bounty-program-d09c27931054"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <dt>Express TestNet Bug Bounty Program</dt>
                      <dd>
                        The Express Protocol Testnet is LIVE! With this launch,
                        we have got one step closer to achieving our grand,,,
                      </dd>
                    </a>
                  </dl>
                </div>
                <div>
                  <h2>Socials</h2>
                  <ul className="grid social-list-2">
                    <li className="social-icon">
                      <a
                        href="https://twitter.com/expressprotocol"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M23.6435 4.93561C22.8085 5.30561 21.9115 5.55561 20.9685 5.66861C21.9415 5.0864 22.6694 4.17008 23.0165 3.09061C22.1023 3.6336 21.1018 4.01581 20.0585 4.22061C19.3569 3.47148 18.4276 2.97494 17.4148 2.80809C16.4021 2.64123 15.3626 2.8134 14.4577 3.29786C13.5529 3.78233 12.8333 4.55197 12.4107 5.48732C11.988 6.42266 11.886 7.47136 12.1205 8.47061C10.2682 8.37761 8.45613 7.89616 6.80192 7.05752C5.14772 6.21887 3.68834 5.04177 2.5185 3.60261C2.1185 4.29261 1.8885 5.09261 1.8885 5.94461C1.88805 6.71161 2.07693 7.46685 2.43838 8.14334C2.79982 8.81983 3.32266 9.39665 3.9605 9.82261C3.22078 9.79907 2.49737 9.5992 1.8505 9.23961V9.29961C1.85043 10.3754 2.22253 11.418 2.90368 12.2506C3.58483 13.0832 4.53307 13.6545 5.5875 13.8676C4.90128 14.0533 4.18184 14.0807 3.4835 13.9476C3.781 14.8732 4.3605 15.6826 5.14087 16.2625C5.92124 16.8424 6.86342 17.1638 7.8355 17.1816C6.18533 18.477 4.14739 19.1797 2.0495 19.1766C1.67788 19.1767 1.30658 19.155 0.9375 19.1116C3.06698 20.4808 5.54584 21.2074 8.0775 21.2046C16.6475 21.2046 21.3325 14.1066 21.3325 7.95061C21.3325 7.75061 21.3275 7.54861 21.3185 7.34861C22.2298 6.68959 23.0164 5.87351 23.6415 4.93861L23.6435 4.93561Z" />
                        </svg>
                        <span>Twitter</span>
                      </a>
                    </li>
                    <li className="social-icon">
                      <a
                        href="https://www.youtube.com/channel/UCHmg9KoZEOG91ppA8NA8Zrw/featured"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M22.5396 6.42C22.4208 5.94541 22.1789 5.51057 21.8382 5.15941C21.4976 4.80824 21.0703 4.55318 20.5996 4.42C18.8796 4 11.9996 4 11.9996 4C11.9996 4 5.1196 4 3.3996 4.46C2.92884 4.59318 2.50157 4.84824 2.16094 5.19941C1.82031 5.55057 1.57838 5.98541 1.4596 6.46C1.14481 8.20556 0.990831 9.97631 0.999595 11.75C0.988374 13.537 1.14236 15.3213 1.4596 17.08C1.59055 17.5398 1.8379 17.9581 2.17774 18.2945C2.51758 18.6308 2.93842 18.8738 3.3996 19C5.1196 19.46 11.9996 19.46 11.9996 19.46C11.9996 19.46 18.8796 19.46 20.5996 19C21.0703 18.8668 21.4976 18.6118 21.8382 18.2606C22.1789 17.9094 22.4208 17.4746 22.5396 17C22.852 15.2676 23.0059 13.5103 22.9996 11.75C23.0108 9.96295 22.8568 8.1787 22.5396 6.42Z"
                            fill="currentColor"
                          />
                          <path
                            d="M9.75 15.02L15.5 11.75L9.75 8.47998V15.02Z"
                            fill="white"
                          />
                        </svg>
                        <span>YouTube</span>
                      </a>
                    </li>
                    <li className="social-icon">
                      <a
                        href="https://t.me/PandoraProtocol"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M20.6646 3.71536L2.93461 10.5524C1.72461 11.0384 1.73161 11.7134 2.71261 12.0144L7.26461 13.4344L17.7966 6.78935C18.2946 6.48635 18.7496 6.64935 18.3756 6.98135L9.84261 14.6824H9.84061L9.84261 14.6834L9.52861 19.3754C9.98861 19.3754 10.1916 19.1644 10.4496 18.9154L12.6606 16.7654L17.2596 20.1624C18.1076 20.6294 18.7166 20.3894 18.9276 19.3774L21.9466 5.14936C22.2556 3.91036 21.4736 3.34936 20.6646 3.71536Z" />
                        </svg>
                        <span>Telegram</span>
                      </a>
                    </li>
                    <li className="social-icon">
                      <a
                        href="https://medium.com/pandoraprotocol/expressprotocol/home"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M3 3V21H21V3H3ZM17.9542 7.26525L16.9875 8.19C16.9467 8.22115 16.9152 8.26281 16.8963 8.31051C16.8774 8.3582 16.8719 8.41014 16.8802 8.46075V15.2618C16.8719 15.3124 16.8774 15.3643 16.8963 15.412C16.9152 15.4597 16.9467 15.5013 16.9875 15.5325L17.9303 16.458V16.6605H13.188V16.458L14.1652 15.51C14.2612 15.414 14.2612 15.3855 14.2612 15.24V9.7425L11.5463 16.6395H11.1788L8.0175 9.7425V14.3647C8.00449 14.4606 8.01347 14.5582 8.04374 14.65C8.07402 14.7419 8.1248 14.8257 8.19225 14.895L9.46275 16.4363V16.6388H5.86125V16.4363L7.1325 14.895C7.1994 14.8256 7.24905 14.7415 7.27745 14.6494C7.30585 14.5573 7.3122 14.4598 7.296 14.3647V9.0195C7.30343 8.9464 7.29351 8.87259 7.26706 8.80404C7.2406 8.7355 7.19836 8.67416 7.14375 8.625L6.01425 7.26525V7.062H9.51975L12.2295 13.0043L14.6115 7.06125H17.9535L17.9542 7.26525Z" />
                        </svg>
                        <span>Medium</span>
                      </a>
                    </li>
                    <li className="social-icon">
                      <a
                        href="https://discord.gg/E7k4m4nzyc"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <svg
                          width="24"
                          height="20"
                          viewBox="0 0 24 20"
                          fill="currentColor"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clipPath="url(#clip0_2183_54985)">
                            <path d="M20.317 2.35981C18.7873 1.65793 17.147 1.14081 15.4319 0.844641C15.4007 0.838925 15.3695 0.85321 15.3534 0.88178C15.1424 1.257 14.9087 1.74649 14.7451 2.13124C12.9004 1.85507 11.0652 1.85507 9.25832 2.13124C9.09465 1.73794 8.85248 1.257 8.64057 0.88178C8.62448 0.854163 8.59328 0.839879 8.56205 0.844641C6.84791 1.13987 5.20756 1.65698 3.67694 2.35981C3.66368 2.36552 3.65233 2.37506 3.64479 2.38743C0.533392 7.03579 -0.31895 11.5699 0.0991801 16.0478C0.101072 16.0697 0.11337 16.0906 0.130398 16.104C2.18321 17.6115 4.17171 18.5267 6.12328 19.1333C6.15451 19.1429 6.18761 19.1315 6.20748 19.1057C6.66913 18.4753 7.08064 17.8106 7.43348 17.1115C7.4543 17.0706 7.43442 17.022 7.39186 17.0058C6.73913 16.7582 6.1176 16.4563 5.51973 16.1135C5.47244 16.0859 5.46865 16.0182 5.51216 15.9859C5.63797 15.8916 5.76382 15.7935 5.88395 15.6944C5.90569 15.6764 5.93598 15.6725 5.96153 15.684C9.88928 17.4772 14.1415 17.4772 18.023 15.684C18.0485 15.6716 18.0788 15.6754 18.1015 15.6935C18.2216 15.7925 18.3475 15.8916 18.4742 15.9859C18.5177 16.0182 18.5149 16.0859 18.4676 16.1135C17.8697 16.463 17.2482 16.7582 16.5945 17.0049C16.552 17.0211 16.533 17.0706 16.5538 17.1115C16.9143 17.8096 17.3258 18.4743 17.7789 19.1048C17.7978 19.1315 17.8319 19.1429 17.8631 19.1333C19.8241 18.5267 21.8126 17.6115 23.8654 16.104C23.8834 16.0906 23.8948 16.0706 23.8967 16.0487C24.3971 10.8718 23.0585 6.37488 20.3482 2.38838C20.3416 2.37506 20.3303 2.36552 20.317 2.35981ZM8.02002 13.3212C6.8375 13.3212 5.86313 12.2356 5.86313 10.9023C5.86313 9.56901 6.8186 8.48336 8.02002 8.48336C9.23087 8.48336 10.1958 9.57854 10.1769 10.9023C10.1769 12.2356 9.22141 13.3212 8.02002 13.3212ZM15.9947 13.3212C14.8123 13.3212 13.8379 12.2356 13.8379 10.9023C13.8379 9.56901 14.7933 8.48336 15.9947 8.48336C17.2056 8.48336 18.1705 9.57854 18.1516 10.9023C18.1516 12.2356 17.2056 13.3212 15.9947 13.3212Z" />
                          </g>
                          <defs>
                            <clipPath id="clip0_2183_54985">
                              <rect
                                width="24"
                                height="18.5915"
                                fill="white"
                                transform="translate(0 0.704224)"
                              />
                            </clipPath>
                          </defs>
                        </svg>
                        <span>Discord</span>
                      </a>
                    </li>
                    <li className="social-icon">
                      <a
                        href="https://github.com/Pandora-Finance"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M9.53624 24C9.53624 24 9.40588 23.4585 9.53624 20.6162C8.13973 20.6646 7.2574 20.5672 6.88923 20.3242C6.33705 19.9595 5.78419 18.835 5.29368 18.0425C4.80317 17.2501 3.71444 17.1248 3.27482 16.9476C2.83519 16.7704 2.72483 16.0483 4.48623 16.5944C6.24767 17.1406 6.33354 18.6279 6.88923 18.9757C7.44492 19.3235 8.77334 19.1713 9.37554 18.8927C9.97767 18.6141 9.93339 17.5768 10.0408 17.1659C10.1765 16.7823 9.69801 16.6972 9.68749 16.6941C9.09965 16.6941 6.01141 16.0196 5.16311 13.0165C4.31489 10.0135 5.40759 8.05058 5.99051 7.20678C6.37911 6.64422 6.34473 5.44533 5.88738 3.60997C7.54798 3.39689 8.82942 3.92174 9.73185 5.18466C9.73272 5.19191 10.9149 4.47874 12.7832 4.47874C14.6515 4.47874 15.3143 5.05964 15.8258 5.18466C16.3373 5.30968 16.7465 2.97397 19.9056 3.60997C19.246 4.91175 18.6937 6.53842 19.1153 7.20678C19.5368 7.87513 21.1914 10.0004 20.1751 13.0165C19.4975 15.0273 18.1655 16.2531 16.1792 16.6941C15.9513 16.767 15.8375 16.8848 15.8375 17.0473C15.8375 17.291 16.1454 17.3176 16.5891 18.4594C16.8848 19.2205 16.6531 24 16.6531 24H15.1571H13.1651H11.325H9.53624Z" />
                        </svg>
                        <span>GitHub</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
          </ul>
        </nav>
        <div className="desktop-btn">
          {active && chainId === 56 && <button className="btn btn-secondary" onClick={()=>modal(<ChainIdReg />)}>
            {/* Chain Id Registration */}
            SPACE ID .bnb domain Registration
          </button>}
          {active ? (
            <Chains />
          ) : (
            <button onClick={connectWalletClick} className="btn btn-secondary">
              Connect Wallet
              <svg
                width="29"
                height="28"
                viewBox="0 0 29 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.5117 21L17.5117 14L10.5117 7"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          )}
        </div>
      </div>
    </header>
  );
};

export default Navigation;
