import React, { useContext, useEffect, useState } from 'react';
import { createPandoraExpressSDK } from 'pandora-express';
import CoinbaseWalletSDK from '@coinbase/wallet-sdk';
// import WalletConnectProvider from '@walletconnect/web3-provider';
import Web3Modal from 'web3modal';
import Web3Provider from 'web3';
import Moralis from 'moralis/node';
// import {providers , ethers} from "ethers";
// @ts-ignore
// import  {getSidAddress} from "@siddomains/sidjs";

const pandora = createPandoraExpressSDK();

const providerOptions = {
  coinbasewallet: {
    package: CoinbaseWalletSDK,
    options: {
      appName: 'Express Protocol Demos',
      infuraId: process.env.REACT_APP_INFURA_KEY,
    },
  },
  // walletconnect: {
  //   package: WalletConnectProvider,
  //   options: {
  //     infuraId: process.env.REACT_APP_INFURA_KEY,
  //   },
  // },
};

const web3Modal = new Web3Modal({
  providerOptions,
});


type ContextType = {
  pandora: any;
  provider: any;
  library: any;
  active: boolean;
  account: string;
  dnsName: string;
  chainId: number;
  login: any;
  logout: any;
};

const Value: ContextType = {
  pandora: pandora,
  provider: null,
  library: null,
  active: false,
  account: '',
  dnsName: '',
  chainId: 0,
  login: null,
  logout: null,
};

const Wallet = React.createContext<ContextType>(Value);

const WalletProvider = ({
  children,
}: {
  children: JSX.Element;
}): JSX.Element => {
  const [provider, setProvider] = useState<any>(null);
  const [library, setLibrary] = useState<any>(null);
  const [active, setActive] = useState<boolean>(false);
  const [account, setAccount] = useState<string>("");
  const [dnsName, setDnsName] = useState("");
  const [chainId, setChainId] = useState<number>(0);

  const login = async () => {
    const SID = require('@siddomains/sidjs').default  
    const SIDfunctions = require('@siddomains/sidjs') 
    try {
      // await Moralis.start({
      //   serverUrl: `${process.env.REACT_APP_SERVER_URL}`,
      //   appId: `${process.env.REACT_APP_APP_ID}`,
      // });
      const provider = await web3Modal.connect();
      const library = new Web3Provider(provider);
      const network = await library.eth.getChainId();
      const accounts = await library.eth.getAccounts();
      setAccount(accounts[0])
      setChainId(network);
      setLibrary(library);
      setActive(true);
      setProvider(provider);
      // const etherProvider = new ethers.providers.JsonRpcProvider(`https://mainnet.infura.io/v3/${process.env.REACT_APP_INFURA_KEY}`)
      // const getEnsName= async(address: string) => {
      //   if (!dnsName) {
      //     etherProvider.lookupAddress(address).then((dnsName)=>{
      //       setDnsName(dnsName as string)
      //     })
      //   }
      // }
      // getEnsName("0x440dc588584D3889E9BB0980766F7CA4dAD78a4C")
      // getEnsName(accounts[0])
      if (network === 56){
        console.log(network)
        const sid = new SID({ provider, sidAddress: SIDfunctions.getSidAddress(`${network}`) })
        // const domainName = await sid.getName("0xC1c50f4F977A15619e7dA92Af825C767306f3087")
        const domainName = await sid.getName(accounts[0])
        setDnsName(domainName.name)
      }

    } catch (error) {
      console.log(error);
      setAccount('');
      setDnsName('');
      setChainId(0);
      setActive(false);
      setProvider(null);
      setLibrary(null);
    }
  };

  const logout = async () => {
    localStorage.clear();
    web3Modal.clearCachedProvider();
    setAccount('');
    setDnsName("")
    setChainId(0);
    setActive(false);
    setProvider(null);
    setLibrary(null);
    window.location.reload();
  };

  useEffect(() => {
    window.addEventListener('beforeunload', () => {
      if (active) {
        logout();
      }
    });
  });

  useEffect(() => {
    if (provider?.on) {
      const handleAccountsChanged = (accounts: string[]) => {
        console.log(accounts);
        setAccount(accounts[0]);
      };

      const handleChainChanged = async (chainId: number) => {
        if (String(chainId).indexOf('0x') > -1) {
          setChainId(parseInt(chainId + '', 16));
        } else {
          setChainId(chainId);
        }
      };

      const handleDisconnect = () => {
        logout();
      };

      provider.on('accountsChanged', handleAccountsChanged);
      provider.on('chainChanged', handleChainChanged);
      provider.on('disconnect', handleDisconnect);

      return () => {
        if (provider.removeListener) {
          provider.removeListener('accountsChanged', handleAccountsChanged);
          provider.removeListener('chainChanged', handleChainChanged);
          provider.removeListener('disconnect', handleDisconnect);
        }
      };
    }
  }, [provider]);

  return (
    <Wallet.Provider
      value={{
        pandora: pandora,
        provider,
        library,
        active,
        account,
        dnsName,
        chainId,
        login,
        logout,
      }}>
      {children}
    </Wallet.Provider>
  );
};

export default WalletProvider;

export const useWallet = () => {
  const walletContext = useContext(Wallet);
  const hook: ContextType = { ...walletContext };
  return hook;
};
