import { FileType, MessageStatus } from '../types';
import { createPandoraExpressSDK } from 'pandora-express';

const pandora = createPandoraExpressSDK();
const PINATA_KEY = process.env.REACT_APP_PINATA_API_KEY;
const PINATA_SECRET = process.env.REACT_APP_PINATA_API_SECRET;

export const uploadFileToPinata = async (file: FileType) => {
  try {
    let result = await pandora.pinata.upload(
      file,
      'Express Demo',
      PINATA_KEY,
      PINATA_SECRET
    );
    if (result.status === 200) {
      return process.env.NODE_ENV === 'development'
        ? `${process.env.REACT_APP_IPFS_LINK_DEV}${result.data.IpfsHash}`
        : `${process.env.REACT_APP_IPFS_LINK_PROD}${result.data.IpfsHash}`;
    } else {
      return null;
    }
  } catch (err) {
    console.log(err);
    return null;
  }
};

export const uploadJSONToPinata = async (json: any) => {
  try {
    let result = await pandora.pinata.pinJSON(PINATA_KEY, PINATA_SECRET, json);
    if (result.status === 200) {
      return process.env.NODE_ENV === 'development'
        ? `${process.env.REACT_APP_IPFS_LINK_DEV}${result.data.IpfsHash}`
        : `${process.env.REACT_APP_IPFS_LINK_PROD}${result.data.IpfsHash}`;
    } else {
      return null;
    }
  } catch (err) {
    console.log(err);
    return null;
  }
};

export const getPreviewImage = (file: FileType): string | undefined => {
  if (file) {
    try {
      return URL.createObjectURL(file);
    } catch (err) {
      if (err) return;
    }
  }
  return;
};

export const copyToClipboard = (text: string) => {
  if (!navigator.clipboard) {
    return;
  }
  navigator.clipboard.writeText(text).then(
    function () {
      console.log('Async: Copying to clipboard was successful!');
    },
    function (err) {
      console.error('Async: Could not copy text: ', err);
    }
  );
};

export const checkLogin = (
  chain: number,
  address: string,
  message: any,
  navigate: any
): boolean => {
  if (!address || (address === '' && !chain) || chain === 0) {
    message('Please connect to your wallet first.', MessageStatus.Error, 3000);
    navigate('/connect');
    return true;
  }
  return false;
};
