import Moralis from 'moralis/node';

export const saveCollection = async (
  address: string,
  name: string,
  symbol: string,
  description: string,
  creator: string,
  chain: number
) => {
  try {
    let Collection = Moralis.Object.extend('Collection');
    let collection = new Collection();

    collection.set('address', address.toLocaleLowerCase());
    collection.set('name', name);
    collection.set('symbol', symbol);
    collection.set('description', description);
    collection.set('creator', creator.toLocaleLowerCase());
    collection.set('chain', chain);

    await collection.save();

    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const getAllCollections = async (chain: number, address: string) => {
  let collections1, collections2;
  const Collection = Moralis.Object.extend('Collection');

  try {
    const query1 = new Moralis.Query(Collection);
    query1.equalTo('chain', chain || 97);
    query1.equalTo('name', 'Public Collection');
    const result1 = await query1.find();
    collections1 = result1.map((collection) => collection.attributes);
  } catch (error) {
    console.log(error);
    console.log('Not able to fetch nfts.');
    return [];
  }

  try {
    const query2 = new Moralis.Query(Collection);
    query2.equalTo('chain', chain);
    query2.equalTo('creator', address.toLowerCase());
    const result2 = await query2.find();
    collections2 = result2.map((collection) => collection.attributes);
  } catch (error) {
    console.log(error);
    console.log('Not able to fetch nfts.');
    return [...collections1];
  }

  return [...collections1, ...collections2];
};
