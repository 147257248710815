import React, { useContext, useState } from 'react';
import Modal from '../components/Modal';

type ModalFunction = (component: JSX.Element) => void;

type ContextType = {
  modal: ModalFunction;
};

const Value: ContextType = {
  modal: (component: JSX.Element) => undefined,
};

const ModalContext = React.createContext<ContextType>(Value);

const ModalProvider = ({
  children,
}: {
  children: JSX.Element;
}): JSX.Element => {
  const [display, setDisplay] = useState<boolean>(false);
  const [component, setComponent] = useState<JSX.Element>(<></>);

  const modal = (component: JSX.Element) => {
    if (component) {
      setComponent(component);
      setDisplay(true);
    }
  };

  return (
    <ModalContext.Provider value={{ modal }}>
      {display && <Modal close={() => setDisplay(false)}>{component}</Modal>}
      {children}
    </ModalContext.Provider>
  );
};

export default ModalProvider;

export const useModal = () => {
  const { modal } = useContext(ModalContext);

  const hook: ModalFunction = modal;

  return hook;
};
