import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Navigation from './components/Navigation';
import {
  UserProvider,
  MessageProvider,
  ModalProvider,
  WalletProvider,
} from './context';
import './index.css';
// import App from './pages/App';
import Dapps from './pages/Dapps';
import Collection from './pages/Collection';
import Dropdapp from './pages/Dropdapp';
import Marketplace from './pages/Marketplace';
import Minting from './pages/Minting';
import Erc404 from './pages/Erc404';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <WalletProvider>
      <MessageProvider>
        <UserProvider>
          <ModalProvider>
            <BrowserRouter>
              <Navigation />
              <Routes>
                <Route path='/' element={<Dapps />} />
                {/* <Route path='/connect' element={<App />} /> */}
                <Route path='/demo'>
                  {/* <Route path='minting' element={<Minting />} />
                  <Route path='marketplace' element={<Marketplace />} />
                  <Route path='dropdapp' element={<Dropdapp />} />
                  <Route path='collection' element={<Collection />} /> */}
                  <Route path="erc404" element={<Erc404/>}/>
                </Route>
              </Routes>
            </BrowserRouter>
          </ModalProvider>
        </UserProvider>
      </MessageProvider>
    </WalletProvider>
  </React.StrictMode>
);
