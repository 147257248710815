import { FormEventHandler, useState } from 'react';
import { useMessage, useUser, useWallet } from '../../context';
import { saveSaleAuction } from '../../helper';
import { MessageStatus } from '../../types';
import PriceInput from '../PriceInput';

const SellFormModal = ({ nft }: { nft: any }) => {
  const [price, setPrice] = useState<string>('');

  const { tokenAddress, tokenId } = nft;

  const { pandora, getLatestNfts } = useUser();
  const { chainId, account, library } = useWallet();
  const message = useMessage();

  const handleSubmit: FormEventHandler = async (event) => {
    event.preventDefault();

    if (price === '') return;

    let transaction: any,
      sellPrice: string = price;

    try {
      message('Calling sale smart contract', MessageStatus.Process);
      transaction = await pandora.erc721.collection.sellNFT(
        library,
        chainId,
        tokenAddress,
        tokenId,
        library?.utils.toWei(sellPrice),
        account
      );
      transaction = transaction.events.TokenMetaReturn.returnValues.data;
    } catch (error) {
      message(
        'Not able to run smart contract function. Please check your form data.',
        MessageStatus.Error,
        3000
      );
      console.log(error);
      return;
    }
    try {
      message('Saving all the data', MessageStatus.Process);
      const result = await saveSaleAuction(transaction, chainId!);
      if (result) {
        message('You have put your NFT on sale.', MessageStatus.Success, 3000);
        getLatestNfts && getLatestNfts();
      } else {
        message('Not able to saved all data', MessageStatus.Error, 3000);
      }
    } catch (error) {
      message(
        'Something went wrong while saving all data',
        MessageStatus.Error,
        3000
      );
      console.log(error);
      return;
    }
  };

  return (
    <form onSubmit={handleSubmit} style={{width: "300px", marginTop:"20px"}}>
      <h2 className='text-center h-s m-bottom-16'>Sell Your NFT</h2>
      <div className='flex flex-col align-start row-gap-8 m-bottom-12'>
        <label htmlFor='price'>Sell Price</label>
        <PriceInput
          setPrice={(value) => setPrice(value)}
          name='price'
          id='price'
          placeholder='Price'
        />
      </div>
      <button className='btn btn-primary width-full'>Sell</button>
    </form>
  );
};

export default SellFormModal;
