import classes from './Modal.module.css';

type Props = {
  children: JSX.Element;
  close: () => void;
};

const Modal = (props: Props) => {
  const { children, close } = props;

  return (
    <div
      className={`${classes.container} width-viewport height-viewport height-view position-fixed flex justify-center align-center`}>
      <div className={`${classes.modal} bg-black-100 border-radius-8 p-20`}>
        {/* <div className='width-full flex justify-end align-center m-bottom-16'> */}
        <div className={classes.innerDiv}>
          <button onClick={close} className='border-none bg-black-100'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'
              stroke='currentColor'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
              className='feather feather-x'>
              <line x1='18' y1='6' x2='6' y2='18'></line>
              <line x1='6' y1='6' x2='18' y2='18'></line>
            </svg>
          </button>
        </div>
        {children}
      </div>
    </div>
  );
};

export default Modal;
