import React, { useContext, useEffect, useState } from 'react';
import { createPandoraExpressSDK } from 'pandora-express';

import { getAllCollections, getAllMyNFTs } from '../helper';
import { useWallet } from './wallet';

const pandora = createPandoraExpressSDK();

type ContextType = {
  pandora: any;
  nfts?: any[];
  getLatestNfts?: any;
  collections?: any[];
  getCollections?: any;
};

const Value: ContextType = {
  pandora: pandora,
};

const User = React.createContext<ContextType>(Value);

const UserProvider = ({ children }: { children: JSX.Element }): JSX.Element => {
  const [nfts, setNfts] = useState<any[]>([]);
  const [collections, setCollections] = useState<any[]>([]);

  const { chainId, account, active } = useWallet();

  const getLatestNfts = async () => {
    const results = await getAllMyNFTs(chainId!, account!);
    setNfts(results);
  };

  const getCollections = async () => {
    const results = await getAllCollections(chainId!, account!);
    setCollections(results);
  };

  useEffect(() => {
    if (active) {
      getLatestNfts();
      getCollections();
    } else {
      setNfts([]);
      setCollections([]);
    }
  }, [account, chainId, active]);

  return (
    <User.Provider
      value={{
        pandora: pandora,
        nfts,
        getLatestNfts,
        collections,
        getCollections,
      }}>
      {children}
    </User.Provider>
  );
};

export default UserProvider;

export const useUser = () => {
  const userContext = useContext(User);
  const hook: ContextType = { ...userContext };
  return hook;
};
