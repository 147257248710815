import { FormEventHandler, useRef, useState } from 'react';
import { useMessage, useUser, useWallet } from '../../context';
import { saveSaleAuction } from '../../helper';
import { MessageStatus } from '../../types';
import PriceInput from '../PriceInput';

const AuctionFormModal = ({ nft }: { nft: any }) => {
  const [price, setPrice] = useState<string>('');
  const timeInputRef = useRef<HTMLInputElement>(null);

  const { tokenAddress, tokenId } = nft;

  const { pandora, getLatestNfts } = useUser();
  const { chainId, account, library } = useWallet();
  const message = useMessage();

  const handleSubmit: FormEventHandler = async (event) => {
    event.preventDefault();

    if (price === '' || timeInputRef.current === null) return;

    let transaction,
      auctionPrice: string = price,
      auctionTime: string | Date | number = timeInputRef.current.value;

    try {
      message('Calling auction smart contract', MessageStatus.Process);
      auctionTime = new Date(auctionTime || new Date());
      auctionTime = auctionTime.getTime() / 1000;

      transaction = await pandora.erc721.collection.sellNFTByBid(
        library,
        chainId,
        tokenAddress,
        tokenId,
        library?.utils.toWei(auctionPrice),
        account,
        auctionTime
      );
      transaction = transaction.events.TokenMetaReturn.returnValues.data;
    } catch (error) {
      message(
        'Not able to call smart contract function. Please check your form data.',
        MessageStatus.Error,
        3000
      );
      console.log(error);
      return;
    }
    try {
      message('Saving all the data', MessageStatus.Process);
      const result = await saveSaleAuction(transaction, chainId!);
      if (result) {
        message(
          'Successfully put your NFT on auction',
          MessageStatus.Success,
          3000
        );
        getLatestNfts && getLatestNfts();
      } else {
        message('Not able to saved all data', MessageStatus.Error, 3000);
      }
    } catch (error) {
      message(
        'Something went wrong while saving all data',
        MessageStatus.Error,
        3000
      );
      console.log(error);
      return;
    }
  };

  return (
    <form onSubmit={handleSubmit} style={{width:"300px", marginTop:"20px"}}>
      <h2 className='text-center h-s m-bottom-16'>Auction Your NFT</h2>
      <div className='flex flex-col align-start row-gap-8 m-bottom-12'>
        <label htmlFor='price'>Auction Price</label>
        <PriceInput
          setPrice={(value) => setPrice(value)}
          name='price'
          id='price'
          placeholder='Price'
        />
      </div>
      <div className='flex flex-col align-start row-gap-8 m-bottom-12'>
        <label htmlFor='time'>Auction Time</label>
        <input
          ref={timeInputRef}
          className='display-block width-full border-radius-4 border-none p-y-4 p-x-12 bg-black-300 text-black-600 body-s'
          type='datetime-local'
          name='time'
          id='time'
          placeholder='0.432'
        />
      </div>
      <button className='btn btn-primary width-full'>Auction</button>
    </form>
  );
};

export default AuctionFormModal;
