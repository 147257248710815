import ScrollSpy from "react-ui-scrollspy";
import BackBtn from "../../components/BackBtn";
import LearnMoreBtn from "../../components/LearnMoreBtn";
import VerticalNavigation from "../../components/VerticalNavigation";
import classes from "./Erc404.module.css";
import Footer from "../../components/Footer";
import { useMessage, useWallet } from "../../context";
import Label from "../../components/Label";
import TokenAddressInput from "../../components/TokenAddressInput";
import IdInput from "../../components/IdInput";
import { useState, useEffect } from "react";
import { ethers } from "ethers";
import { DeployERC404 } from "../../abi/erc404Factory";
import { decodeEventLog, parseGwei } from "viem";
import factoryERC404 from "../../abi/ERC404Factory.json";
import erc404 from "../../abi/ERC404.json";
import {
  transferTokens,
  whiteList,
  transfer_NFT,
  set_gateway,
  set_token_uri,
} from "../../abi/erc404";
import { parseEther } from "viem";
import { MessageStatus } from "../../types";
import { copyToClipboard } from "../../helper";
import Copy from "../../images/clipboard.svg";
import attachment from "../../images/attachment.png";
import info from "../../images/info.png"
import checked from "../../images/check-circle-blue.png";
import axios from "axios";
import JSZip from "jszip";

const TABS = [
  "create_collection",
  "Upload_NFT_Data",
  "confirm_whitelisting",
  "transfer_tokens",
  "transfer_NFT",
];

const Erc404 = (): JSX.Element => {
  const message = useMessage();

  const { chainId, account, active, library, login } = useWallet();

  const [upload_status, setUpload_status] = useState({
    media_upload: false,
    json_upload: false,
    media: null,
    json: null,
  });

  const [selectedFolder, setSelectedFolder] = useState<any>(null);
  const [selectedMetadata, setSelectedMetadata] = useState<any>(null);
  const [fileType, setFileType] = useState(null);

  const [pinataHash, setPinataHash] = useState(null);

  const [fileCount, setFileCount] = useState(0);

  // create collection
  const [collectionName, setCollectionName] = useState<string>("");
  const [collectionSymbol, setCollectionSymbol] = useState<string>("");
  const [totalSupply, setTotalSupply] = useState<string>("");

  //  whiteList
  const [whitelist_collection, set_whitelist_collection] = useState<string>("");

  // transfer token
  const [tokenCollectionAddress, setTokenCollectionAddress] =
    useState<string>("");
  const [tokenReceiverAddress, setTokenReceiverAddress] = useState<string>("");
  const [tokenCount, setTokenCount] = useState<string>("");

  // transfer nft
  const [transferCollectionAddress, setTransferCollectionAddress] =
    useState<string>("");
  const [transderReceiverAddress, setTransferReceiverAddress] =
    useState<string>("");
  const [transferTokenId, setTransferTokenId] = useState<string>("");

  const [transactionHash, setTransactionHash] = useState({
    collectionHash: "",
    uploadUriHash:"",
    whitelistHash:"",
    tokenHash:"",
    nftHash:""
  });
  const [newAddress, setNewAddress] = useState("");

  const [button_status, set_button_status] = useState<any>({
    collection: null,
    uploadIPFS: null,
    whitelist: null,
    token: null,
    nft: null,
  });

  const PINATA_KEY = process.env.REACT_APP_PINATA_API_KEY;
  const PINATA_SECRET = process.env.REACT_APP_PINATA_API_SECRET;

  const splitPath = (path: string) => {
    const match = path.match(/^([a-zA-Z]+)/); // Regular expression to match the first word
    const result = match ? match[1] : null; // Extract the matched word
    return result; // Output: Media
  };

  const handleFolderChange = (event: any) => {
    const folder = event.target.files;
    const file = event.target.files[0];
    const imageType = file.type.split("/")[1];

    setFileType(imageType);
    setSelectedFolder(folder);
    setFileCount(folder?.length);
  };
  const handleMetadataChange = (event: any) => {
    const folder = event.target.files;
    setSelectedMetadata(folder);
  };

  const createMetadataFiles = async (hash: string) => {
    // Create a new ZIP archive
    const zip = new JSZip();

    const path = splitPath(selectedFolder![0]?.webkitRelativePath);

    // Generate 5 JSON blobs
    for (let i = 1; i <= 1000; i++) {
      const metadata = {
        name: `${collectionName} #${i}`,
        image: `https://indigo-selective-gazelle-598.mypinata.cloud/ipfs/${hash}/?pinataGatewayToken=bMH5tngnfZ4MuPXvx0dqFPfE5cR9u1MSaZi7UaBvZ8-pXk20h9AqCmzIMVyEjASY`,
      };
      // image: `https://indigo-selective-gazelle-598.mypinata.cloud/ipfs/QmbG4w6XnKPbfNR1edNzKPubw1tZb9NQDfVzNvhB4k82Kc/1.gif?pinataGatewayToken=bMH5tngnfZ4MuPXvx0dqFPfE5cR9u1MSaZi7UaBvZ8-pXk20h9AqCmzIMVyEjASY`,

      // Add each metadata as a separate file inside the metadata folder
      zip
        .folder("metadata")!
        .file(`${i}.json`, JSON.stringify(metadata, null, 2));
    }

    // Generate the ZIP archive
    const content = await zip.generateAsync({ type: "blob" });

    // Create a download link for the ZIP file
    const url = URL.createObjectURL(content);
    const link = document.createElement("a");
    link.href = url;
    link.download = "Metadata.zip";
    document.body.appendChild(link);

    // Trigger the download
    link.click();

    // Clean up
    URL.revokeObjectURL(url);
    document.body.removeChild(link);
  };

  const pinFileToIPFS = async (folder: boolean) => {
    const url = `https://api.pinata.cloud/pinning/pinFileToIPFS`;

    if (!selectedFolder) {
      console.error("No folder selected");
      return;
    }

    let formData = new FormData();
    Array.from(selectedFolder).forEach((file) => {
      formData.append("file", file as any);
    });
    const metadata = JSON.stringify({
      name: "Metadata",
    });
    formData.append("pinataMetadata", metadata);
    const options = JSON.stringify({
      cidVersion: 0,
    });

    formData.append("pinataOptions", options);

    try {
      const response = await axios.post(url, formData, {
        maxBodyLength: 600000000, //this is needed to prevent axios from erroring out with large files
        headers: {
          "Content-Type": `multipart/form-data;`,
          pinata_api_key: PINATA_KEY,
          pinata_secret_api_key: PINATA_SECRET,
        },
      });
      // setPinataHash(response.data?.IpfsHash);

      createMetadataFiles(response.data?.IpfsHash);
      if (response.status === 200) {
        setUpload_status((prev: any) => ({
          ...prev,
          media_upload: true,
          media: null,
        }));
      }

      return response;
    } catch (error) {
      console.log("Error", error);
      setUpload_status((prev: any) => ({
        ...prev,
        media: null,
      }));
    }
  };

  const pinMetadataToIPFS = async () => {
    const url = `https://api.pinata.cloud/pinning/pinFileToIPFS`;

    if (!selectedMetadata) {
      console.error("No folder selected");
      return;
    }

    let formData = new FormData();
    Array.from(selectedMetadata).forEach((file) => {
      formData.append("file", file as any);
    });
    const metadata = JSON.stringify({
      name: "Metadata",
    });
    formData.append("pinataMetadata", metadata);
    const options = JSON.stringify({
      cidVersion: 0,
    });

    formData.append("pinataOptions", options);

    try {
      const response = await axios.post(url, formData, {
        maxBodyLength: 600000000, //this is needed to prevent axios from erroring out with large files
        headers: {
          "Content-Type": `multipart/form-data;`,
          pinata_api_key: PINATA_KEY,
          pinata_secret_api_key: PINATA_SECRET,
        },
      });
      setPinataHash(response.data?.IpfsHash);
      if (response.status === 200) {
        setUpload_status((prev: any) => ({
          ...prev,
          json_upload: true,
          json: null,
        }));
      }
      return response;
    } catch (error) {
      console.log("Error", error);
      setUpload_status((prev: any) => ({
        ...prev,
        json: null,
      }));
    }
  };

  // useEffect(()=>{
  //   if(window.ethereum ){
  //     console.log("njkjdjjsdhkjernjknjenjk")
  //   }
  //   console.log("123245323")
  // }, [])

  // const ethProvider = new ethers.providers.Web3Provider(window.ethereum);

  const callDeployERC404 = async () => {
    if(window.ethereum) {
      const ethProvider = new ethers.providers.Web3Provider(window.ethereum);

      try {
        const contract = new ethers.Contract(
          chainId === 137
            ? "0x81ec5Bc5cbe93c29bABE4A843034E4a400De140d"
            : chainId === 1
              ? "0x54feE22c3f98EF1A7A3a8C31c0E0Bdc4CfF9F3f7"
              :"0x84274341835CEef81F6E003FD60244800d775bb1"
            ,
          [DeployERC404],
          ethProvider.getSigner()
        );

        const tx = await contract.deployERC404(
          collectionName,
          collectionSymbol,
          totalSupply,
          account
        );
        const transaction = await tx.wait();
        // console.log(transaction)

        // Set transaction hash
        if (tx.hash) {
          setTransactionHash((prev: any)=>({
            ...prev,
            collectionHash: tx.hash
          }));
          message("Collection Deployed ", MessageStatus.Success, 4000);
        }

        const logs = transaction.logs?.map((log: any) => {
          try {
            const data = decodeEventLog({ abi: factoryERC404.abi, ...log });
            return data;
          } catch (err) {
            console.log(err);
            return null;
          }
        });
        // console.log(logs)

        const event: any = logs.filter(
          (log: any) => log !== null && log.eventName === "deployedAddress"
        )[0];

        const ERC404Address = event ? event.args.ERC404Address : null;
        setNewAddress(ERC404Address);
        console.log(ERC404Address);
        set_button_status((prev: any) => ({
          ...prev,
          collection: null,
        }));
      } catch (error) {
        console.error("Error calling deployERC404:", error);
        set_button_status((prev: any) => ({
          ...prev,
          collection: null,
        }));
      }
    }
  };

  const whitelistERC404 = async () => {
    if(window.ethereum) {
      const ethProvider = new ethers.providers.Web3Provider(window.ethereum);

    try {
      const contract = new ethers.Contract(
        whitelist_collection,
        [whiteList],
        ethProvider.getSigner()
      );

      const tx = await contract.setWhitelist(account, true);
      const transaction = await tx.wait();

      if (tx.hash) {
        setTransactionHash((prev: any)=>({
          ...prev,
          whitelistHash: tx.hash
        }));
        message("Whitelisting Successfull ", MessageStatus.Success, 4000);
      }
      set_button_status((prev: any) => ({
        ...prev,
        whitelist: null,
      }));

      console.log(transaction);
    } catch (error) {
      console.error("Error whitelisting:", error);
      set_button_status((prev: any) => ({
        ...prev,
        whitelist: null,
      }));
    }
  }
  };

  const transferToken = async () => {
    if(window.ethereum) {
    const ethProvider = new ethers.providers.Web3Provider(window.ethereum);

    try {
      const contract = new ethers.Contract(
        tokenCollectionAddress,
        [transferTokens],
        ethProvider.getSigner()
      );

      const tx = await contract.transfer(
        tokenReceiverAddress,
        parseEther(tokenCount)
      );
      const transaction = await tx.wait();

      if (tx.hash) {
        message("Token Transfered ", MessageStatus.Success, 4000);
        setTransactionHash((prev: any)=>({
          ...prev,
          tokenHash: tx.hash
        }));
      }
      set_button_status((prev: any) => ({
        ...prev,
        token: null,
      }));

      console.log(transaction);
    } catch (error) {
      console.error("Error whitelisting:", error);
      set_button_status((prev: any) => ({
        ...prev,
        token: null,
      }));
    }
  }
  };

  const transferNFT = async () => {
    if(window.ethereum) {
    const ethProvider = new ethers.providers.Web3Provider(window.ethereum);

    try {
      const contract = new ethers.Contract(
        transferCollectionAddress,
        [transfer_NFT],
        ethProvider.getSigner()
      );

      const tx = await contract.safeTransferFrom(
        account,
        transderReceiverAddress,
        transferTokenId
      );
      const transaction = await tx.wait();
      if (tx.hash) {
        setTransactionHash((prev: any)=>({
          ...prev,
          nftHash: tx.hash
        }));
        message("Nft Transfered ", MessageStatus.Success, 4000);
      }
      set_button_status((prev: any) => ({
        ...prev,
        nft: null,
      }));

      console.log(transaction);
    } catch (error) {
      console.error("Error whitelisting:", error);
      set_button_status((prev: any) => ({
        ...prev,
        nft: null,
      }));
    }
  }
  };
  const setGateway = async () => {
    if(window.ethereum) {
    const ethProvider = new ethers.providers.Web3Provider(window.ethereum);

    try {
      const contract = new ethers.Contract(
        newAddress,
        [set_gateway],
        ethProvider.getSigner()
      );

      const tx = await contract.setGateway(
        "?pinataGatewayToken=bMH5tngnfZ4MuPXvx0dqFPfE5cR9u1MSaZi7UaBvZ8-pXk20h9AqCmzIMVyEjASY"
      );
      const transaction = await tx.wait();
      if (tx.hash) {
        setURI();
      }

      console.log(transaction);
    } catch (error) {
      console.error("Error whitelisting:", error);
      set_button_status((prev: any) => ({
        ...prev,
        uploadIPFS: null,
      }));
    }
  }
  };
  const setURI = async () => {
    if(window.ethereum) {
      const ethProvider = new ethers.providers.Web3Provider(window.ethereum);

    try {
      const contract = new ethers.Contract(
        newAddress,
        [set_token_uri],
        ethProvider.getSigner()
      );

      const tx = await contract.setTokenURI(
        `https://indigo-selective-gazelle-598.mypinata.cloud/ipfs/${pinataHash}/`
      );
      const transaction = await tx.wait();
      if (tx.hash) {
        setTransactionHash((prev: any)=>({
          ...prev,
          uploadUriHash: tx.hash
        }));
        message("Token URI has been set. ", MessageStatus.Success, 4000);
      }
      set_button_status((prev: any) => ({
        ...prev,
        uploadIPFS: null,
      }));
      setUpload_status((prev: any) => ({
        ...prev,
        json: null,
        json_upload: false,
        media: null,
        media_upload: false,
      }));

      console.log(transaction);
    } catch (error) {
      console.error("Error whitelisting:", error);
      set_button_status((prev: any) => ({
        ...prev,
        uploadIPFS: null,
      }));
      setUpload_status((prev: any) => ({
        ...prev,
        json: null,
        json_upload: false,
        media: null,
        media_upload: false,
      }));
    }
  }
  };

  return (
    <>
      <div className={classes.container}>
        <div className={classes.col1}>
          <BackBtn to="/" />
          <div className={classes.verticalNavContainer}>
            <VerticalNavigation tabs={TABS} />
          </div>
          {/* <LearnMoreBtn href='https://docs.expressprotocol.io/guide' /> */}
        </div>
        <div className={classes.col2}>
          <h1 className="h-l text-black-500 m-bottom-30 flex align-center col-gap-16">
            Explore ERC-404
            <span className={classes.audited}>
              <img src={info} alt="Done" />
              It’s still experimental and unaudited
            </span>
          </h1>
          <ScrollSpy offsetBottom={100} scrollThrottle={80} useBoxMethod>
            <form
              id={TABS[0]}
              onSubmit={(event) => {
                event.preventDefault();
                callDeployERC404();
                set_button_status((prev: any) => ({
                  ...prev,
                  collection: true,
                }));
              }}
              className="m-bottom-56"
            >
              <div className="m-bottom-32">
                <p className={classes.step}>Step1 :</p>
                <h2 className="h-xl text-black-700 m-bottom-10">
                  Create ERC-404 Collection
                </h2>
                <p className="body-xs text-black-500 m-bottom-15">
                  When you create an ERC 404 collection, every token you allocate generates an equivalent number of NFTs.
                </p>
                <div className={`${classes.inputContainer} flex `}>
                  <div>
                    <Label htmlFor="collection name" label="Collection Name" />
                    <input
                      onBlur={(event) => setCollectionName(event.target.value)}
                      className="display-block width-full border-none border-radius-8 bg-black-250 p-y-8 p-x-24 m-bottom-24 body-s text-black-600"
                      type="text"
                      name="collection name"
                      id="collection name"
                      placeholder="Name"
                    />
                  </div>

                  <div>
                    <Label
                      htmlFor="collection symbol"
                      label="Collection Symbol"
                    />
                    <input
                      onBlur={(event) =>
                        setCollectionSymbol(event.target.value)
                      }
                      className="display-block width-full border-none border-radius-8 bg-black-250 p-y-8 p-x-24 m-bottom-24 body-s text-black-600"
                      type="text"
                      name="collection symbol"
                      id="collection symbol"
                      placeholder="Symbol"
                    />
                  </div>
                </div>
                <div>
                  <Label htmlFor="total supply" label="Total Supply" />
                  <input
                    onBlur={(event) => setTotalSupply(event.target.value)}
                    className="display-block width-full border-none border-radius-8 bg-black-250 p-y-8 p-x-24 m-bottom-24 body-s text-black-600"
                    type="number"
                    name="total supply"
                    id="total supply"
                    placeholder="Total Tokens"
                  />
                </div>
              </div>
              <div
                className={`${classes.inputContainer} flex align-end col-gap-32 m-bottom-20`}
              >
                <div className={classes.btn_row}>
                {active ? (
                  <button
                    disabled={
                      button_status.collection ||
                      button_status.uploadIPFS ||
                      button_status.whitelist ||
                      button_status.token ||
                      button_status.nft
                    }
                    className={`${classes.btn} btn btn-primary width-full`}
                  >
                    {button_status.collection ? (
                      <span className={classes.loading}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-loader"
                        >
                          <line x1="12" y1="2" x2="12" y2="6"></line>
                          <line x1="12" y1="18" x2="12" y2="22"></line>
                          <line x1="4.93" y1="4.93" x2="7.76" y2="7.76"></line>
                          <line
                            x1="16.24"
                            y1="16.24"
                            x2="19.07"
                            y2="19.07"
                          ></line>
                          <line x1="2" y1="12" x2="6" y2="12"></line>
                          <line x1="18" y1="12" x2="22" y2="12"></line>
                          <line
                            x1="4.93"
                            y1="19.07"
                            x2="7.76"
                            y2="16.24"
                          ></line>
                          <line
                            x1="16.24"
                            y1="7.76"
                            x2="19.07"
                            y2="4.93"
                          ></line>
                        </svg>
                      </span>
                    ) : (
                      <span>Create Collection</span>
                    )}
                  </button>
                ) : (
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      login();
                    }}
                    className={`${classes.btn} btn btn-primary width-full`}
                  >
                    Connect Wallet
                  </button>
                )}
                 {transactionHash.collectionHash &&
                <div className={classes.succeed}>
                  <span>ERC-404 Collection Created<img src={checked}/></span>
                </div>}
                </div>
              </div>
              {/* {newAddress && (
                <div className={classes.copyERC}>
                  <span className="body-s text-black-500">
                    ERC-404 Collection Address :
                  </span>
                  <span className="h-s text-black-600">{newAddress}</span>
                  <span
                    onClick={() => {
                      copyToClipboard(newAddress || "");
                      message(
                        "Copied your ERC-404 address",
                        MessageStatus.Success,
                        1500
                      );
                      // setToggle(false);
                    }}
                    className={`${classes.button}  border-none bg-black-300 text-black-600 flex align-center col-gap-4 p-4`}
                  >
                    <img src={Copy} alt="Copy Address" />
                  </span>
                </div>
              )} */}
              {/* <hr className="border-black-400 border-2" /> */}
            </form>

            <form
              id={TABS[1]}
              onSubmit={(event) => {
                event.preventDefault();
                setGateway();
                set_button_status((prev: any) => ({
                  ...prev,
                  uploadIPFS: true,
                }));
              }}
              className="m-bottom-40"
            >
              <div className="m-bottom-32">
                <div className="m-bottom-24">
                <p className={classes.step}>Step2 :</p>
                  <h2 className="h-xl text-black-700 m-bottom-4">
                    Upload NFT Data
                  </h2>
                  <p className="body-xs text-black-500">
                    {/* This step is necessary to authorize the transfer of tokens. */}
                  </p>
                </div>

                <div className={classes.main_div}>
                  <div>
                    <div className={classes.label_tool}>
                    <Label htmlFor="upload Media" label="Upload The NFT Image" />
                    <span className={classes.tooltip}>
                      <img src={info}/>
                    <span className={classes.tooltiptext}>
                      Json download will automatically start after you uploaded the image
                    </span>
                    </span>
                    </div>
                    <div className={classes.outer_div}>
                      <div className={classes.inner_div}>
                        <div className={classes.attachMedia}>
                          {/* <input
                            type="file"
                            id="uploadMedia"
                            // @ts-expect-error
                            webkitdirectory=""
                            mozdirectory
                            directory=""
                            onChange={handleFolderChange}
                          /> */}
                          <input
                            type="file"
                            id="uploadMedia"
                            accept=".png, .gif, .jpg"
                            onChange={handleFolderChange}
                            multiple={false}
                          />
                          <label
                            htmlFor="uploadMedia"
                            style={{ display: "flex" }}
                          >
                            <img src={attachment} />
                            Attach Image
                          </label>

                          {selectedFolder && (
                            <div>
                              <button
                                onClick={(event) => {
                                  event?.preventDefault();
                                  event.stopPropagation();
                                  pinFileToIPFS(true);
                                  setUpload_status((prev: any) => ({
                                    ...prev,
                                    media: true,
                                  }));
                                }}
                                className={
                                  upload_status.media_upload
                                    ? classes.success
                                    : ""
                                }
                              >
                                {upload_status.media ? (
                                  <span className={classes.loading}>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      className="feather feather-loader"
                                    >
                                      <line x1="12" y1="2" x2="12" y2="6"></line>
                                      <line
                                        x1="12"
                                        y1="18"
                                        x2="12"
                                        y2="22"
                                      ></line>
                                      <line
                                        x1="4.93"
                                        y1="4.93"
                                        x2="7.76"
                                        y2="7.76"
                                      ></line>
                                      <line
                                        x1="16.24"
                                        y1="16.24"
                                        x2="19.07"
                                        y2="19.07"
                                      ></line>
                                      <line x1="2" y1="12" x2="6" y2="12"></line>
                                      <line
                                        x1="18"
                                        y1="12"
                                        x2="22"
                                        y2="12"
                                      ></line>
                                      <line
                                        x1="4.93"
                                        y1="19.07"
                                        x2="7.76"
                                        y2="16.24"
                                      ></line>
                                      <line
                                        x1="16.24"
                                        y1="7.76"
                                        x2="19.07"
                                        y2="4.93"
                                      ></line>
                                    </svg>
                                  </span>
                                ) : upload_status.media_upload ? (
                                  "Success"
                                ) : (
                                  "Upload Image"
                                )}
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    <span>Your JSON file download will start automatically</span>
                    </div>
                  </div>
                  <div>
                    {/* Selecting the MetaData */}
                    <div className={classes.label_tool}>
                    <Label required htmlFor="uploadJson" label="Upload the Downloaded JSON Folder" />
                    <span className={classes.tooltip}>
                      <img src={info}/>
                      
                    <div className={classes.tooltiptext}>
                    Ensure the JSON file contains accurate data corresponding to your NFTs' attributes and quantities.
                    </div>
                    </span>
                    </div>
                    <div className={classes.outer_div}>
                      <div className={classes.inner_div}>
                        <div className={classes.attachMedia}>
                          <input
                            type="file"
                            id="uploadJson"
                            disabled={!upload_status.media_upload}
                            // @ts-expect-error
                            webkitdirectory=""
                            mozdirectory
                            directory=""
                            onChange={handleMetadataChange}
                          />
                          <label
                            htmlFor="uploadJson"
                            className={
                              upload_status.media_upload
                                ? ""
                                : classes.disableInput
                            }
                            style={{ display: "flex" }}
                          >
                            <img src={attachment} />
                            Attach Folder
                          </label>
                          {selectedMetadata && (
                            <div>
                              <button
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  setUpload_status((prev: any) => ({
                                    ...prev,
                                    json: true,
                                  }));
                                  pinMetadataToIPFS();
                                }}
                                className={
                                  upload_status.json_upload ? classes.success : ""
                                }
                              >
                                {upload_status.json ? (
                                  <span className={classes.loading}>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      className="feather feather-loader"
                                    >
                                      <line x1="12" y1="2" x2="12" y2="6"></line>
                                      <line
                                        x1="12"
                                        y1="18"
                                        x2="12"
                                        y2="22"
                                      ></line>
                                      <line
                                        x1="4.93"
                                        y1="4.93"
                                        x2="7.76"
                                        y2="7.76"
                                      ></line>
                                      <line
                                        x1="16.24"
                                        y1="16.24"
                                        x2="19.07"
                                        y2="19.07"
                                      ></line>
                                      <line x1="2" y1="12" x2="6" y2="12"></line>
                                      <line
                                        x1="18"
                                        y1="12"
                                        x2="22"
                                        y2="12"
                                      ></line>
                                      <line
                                        x1="4.93"
                                        y1="19.07"
                                        x2="7.76"
                                        y2="16.24"
                                      ></line>
                                      <line
                                        x1="16.24"
                                        y1="7.76"
                                        x2="19.07"
                                        y2="4.93"
                                      ></line>
                                    </svg>
                                  </span>
                                ) : upload_status.json_upload ? (
                                  "Success"
                                ) : (
                                  "Upload Folder"
                                )}
                              </button>
                            </div>
                          )}

                        </div>
                      </div>
                      <span>Extract the downloaded zip file and upload the JSON folder</span>

                    </div>
                  </div>
                </div>
                
                <div className={classes.btn_row}>
                {active ? (
                  <button
                    disabled={
                      !upload_status.json_upload ||
                      button_status.collection ||
                      button_status.uploadIPFS ||
                      button_status.whitelist ||
                      button_status.token ||
                      button_status.nft
                    }
                    type="submit"
                    className={`${classes.btn} btn btn-primary width-full`}
                  >
                    {button_status.uploadIPFS ? (
                      <span className={classes.loading}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-loader"
                        >
                          <line x1="12" y1="2" x2="12" y2="6"></line>
                          <line x1="12" y1="18" x2="12" y2="22"></line>
                          <line x1="4.93" y1="4.93" x2="7.76" y2="7.76"></line>
                          <line
                            x1="16.24"
                            y1="16.24"
                            x2="19.07"
                            y2="19.07"
                          ></line>
                          <line x1="2" y1="12" x2="6" y2="12"></line>
                          <line x1="18" y1="12" x2="22" y2="12"></line>
                          <line
                            x1="4.93"
                            y1="19.07"
                            x2="7.76"
                            y2="16.24"
                          ></line>
                          <line
                            x1="16.24"
                            y1="7.76"
                            x2="19.07"
                            y2="4.93"
                          ></line>
                        </svg>
                      </span>
                    ) : (
                      <span>Confirm</span>
                    )}
                  </button>
                ) : (
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      login();
                    }}
                    className={`${classes.btn} btn btn-primary width-full`}
                  >
                    Connect Wallet
                  </button>
                )}
                 {transactionHash.uploadUriHash &&
                <div className={classes.succeed}>
                  <span>Token URI Uploaded <img src={checked}/></span>
                </div>}
                </div>
              </div>
              {/* {newAddress && (
                <div className={classes.copyERC}>
                  <span className="body-s text-black-500">
                    ERC-404 Collection Address :
                  </span>
                  <span className="h-s text-black-600">{newAddress}</span>
                  <span
                    onClick={() => {
                      copyToClipboard(newAddress || "");
                      message(
                        "Copied your ERC-404 address",
                        MessageStatus.Success,
                        1500
                      );
                      // setToggle(false);
                    }}
                    className={`${classes.button}  border-none bg-black-300 text-black-600 flex align-center col-gap-4 p-4`}
                  >
                    <img src={Copy} alt="Copy Address" />
                  </span>
                </div>
              )} */}
             
              {/* {transactionHash.uploadUriHash &&
                <div className={classes.succeed}>
                  <span><img/></img>Whitelisting successfull</span>
                  <span></span>
                </div>} */}
              {/* <hr className="border-black-400 border-2" /> */}
              
            </form>

            {/* WhiteList Code */}

            <form
              id={TABS[2]}
              onSubmit={(event) => {
                event.preventDefault();
                whitelistERC404();
                set_button_status((prev: any) => ({
                  ...prev,
                  whitelist: true,
                }));
              }}
              className="m-bottom-56"
            >
              {newAddress && (
                <div className={classes.copyERC}>
                  <span className="body-s text-black-500">
                    Your ERC-404 Collection Address :
                  </span>
                  <span className="h-s text-black-600">{newAddress}</span>
                  <span
                    onClick={() => {
                      copyToClipboard(newAddress || "");
                      message(
                        "Copied your ERC-404 address",
                        MessageStatus.Success,
                        1500
                      );
                      // setToggle(false);
                    }}
                    className={`${classes.button}  border-none bg-black-300 text-black-600 flex align-center col-gap-4 p-4`}
                  >
                    <img src={Copy} alt="Copy Address" />
                  </span>
                </div>
              )}
              <div className="m-bottom-32">
                <div className="m-bottom-24">
                <p className={classes.step}>Step3 :</p>
                  <h2 className="h-xl text-black-700 m-bottom-4">
                    Confirm Whitelisting
                  </h2>
                  <p className="body-xs text-black-500">
                    This step is necessary to authorize the transfer of tokens.
                  </p>
                </div>
                <div
                  className={`${classes.inputContainer} flex align-start col-gap-32 m-bottom-20`}
                >
                  <TokenAddressInput
                    label="Collection Address"
                    changeHandler={set_whitelist_collection}
                    name="Collection Address"
                    id="Collection Address"
                    placeholder="Your ERC-404 collection address"
                  />
                </div>
                <div className={classes.btn_row}>
                {active ? (
                  <button
                    disabled={
                      button_status.collection ||
                      button_status.uploadIPFS ||
                      button_status.whitelist ||
                      button_status.token ||
                      button_status.nft
                    }
                    className={`${classes.btn} btn btn-primary width-full`}
                  >
                    {button_status.whitelist ? (
                      <span className={classes.loading}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-loader"
                        >
                          <line x1="12" y1="2" x2="12" y2="6"></line>
                          <line x1="12" y1="18" x2="12" y2="22"></line>
                          <line x1="4.93" y1="4.93" x2="7.76" y2="7.76"></line>
                          <line
                            x1="16.24"
                            y1="16.24"
                            x2="19.07"
                            y2="19.07"
                          ></line>
                          <line x1="2" y1="12" x2="6" y2="12"></line>
                          <line x1="18" y1="12" x2="22" y2="12"></line>
                          <line
                            x1="4.93"
                            y1="19.07"
                            x2="7.76"
                            y2="16.24"
                          ></line>
                          <line
                            x1="16.24"
                            y1="7.76"
                            x2="19.07"
                            y2="4.93"
                          ></line>
                        </svg>
                      </span>
                    ) : (
                      <span>Confirm</span>
                    )}
                  </button>
                ) : (
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      login();
                    }}
                    className={`${classes.btn} btn btn-primary width-full`}
                  >
                    Connect Wallet
                  </button>
                )}
                {transactionHash.whitelistHash &&
                <div className={classes.succeed}>
                  <span>Whitelisting successfull <img src={checked}/></span>
                </div>}
                </div>
                
              </div>
              
              <hr className="border-black-400 border-2" />
            </form>

            <form
              id={TABS[3]}
              onSubmit={(event) => {
                event.preventDefault();
                transferToken();
                set_button_status((prev: any) => ({
                  ...prev,
                  token: true,
                }));
              }}
              className="m-bottom-56"
            >
              <div className="m-bottom-32">
                <div className="m-bottom-24">
                  <h2 className="h-xl text-black-700 m-bottom-4">
                    Transfer Tokens
                  </h2>
                  <p className="body-xs text-black-500">
                    When you transfer one full token, 1 Replicant NFT will be
                    minted to the receiver's wallet. Simultaneously, its
                    connected NFT will be burned.{" "}
                  </p>
                </div>

                <div
                  className={`${classes.inputContainer} flex align-start col-gap-32 m-bottom-20`}
                >
                  <TokenAddressInput
                    label="Collection Address"
                    changeHandler={setTokenCollectionAddress}
                    name="Collection Address"
                    id="Collection Address"
                    placeholder="Your ERC-404 collection address"
                  />
                </div>

                <div
                  className={`${classes.inputContainer} flex align-start col-gap-32 m-bottom-20`}
                >
                  <TokenAddressInput
                    label="Receiver Address"
                    changeHandler={setTokenReceiverAddress}
                    name="Receiver Address"
                    id="Receiver Address"
                    placeholder="Wallet Address"
                  />
                  <div>
                    <Label htmlFor="token count" label="Token Count" />
                    <input
                      onBlur={(event) => setTokenCount(event.target.value)}
                      className="display-block width-full border-none border-radius-8 bg-black-250 p-y-8 p-x-24 m-bottom-24 body-s text-black-600"
                      type="number"
                      name="token count"
                      id="token count"
                      placeholder="Token Count"
                    />
                  </div>
                </div>
                <div className={classes.btn_row}>
                {active ? (
                  <button
                    disabled={
                      button_status.collection ||
                      button_status.uploadIPFS ||
                      button_status.whitelist ||
                      button_status.token ||
                      button_status.nft
                    }
                    className={`${classes.btn} btn btn-primary width-full`}
                  >
                    {button_status.token ? (
                      <span className={classes.loading}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-loader"
                        >
                          <line x1="12" y1="2" x2="12" y2="6"></line>
                          <line x1="12" y1="18" x2="12" y2="22"></line>
                          <line x1="4.93" y1="4.93" x2="7.76" y2="7.76"></line>
                          <line
                            x1="16.24"
                            y1="16.24"
                            x2="19.07"
                            y2="19.07"
                          ></line>
                          <line x1="2" y1="12" x2="6" y2="12"></line>
                          <line x1="18" y1="12" x2="22" y2="12"></line>
                          <line
                            x1="4.93"
                            y1="19.07"
                            x2="7.76"
                            y2="16.24"
                          ></line>
                          <line
                            x1="16.24"
                            y1="7.76"
                            x2="19.07"
                            y2="4.93"
                          ></line>
                        </svg>
                      </span>
                    ) : (
                      <span>Confirm Transfer</span>
                    )}
                  </button>
                ) : (
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      login();
                    }}
                    className={`${classes.btn} btn btn-primary width-full`}
                  >
                    Connect Wallet
                  </button>
                )}
                {transactionHash.tokenHash &&
                <div className={classes.succeed}>
                  <span>Transfered <img src={checked}/></span>
                  <span>Great! Receiver can view NFTs on 
                    <a href={chainId===80001?`https://testnets.opensea.io/`:`https://opensea.io/`}  target='_blank' rel='noreferrer'>Opensea</a>
                    & 
                    <a href={chainId===80001?`https://testnet.rarible.com/`:`https://rarible.com/`} target='_blank' rel='noreferrer'>Rarible</a>
                  </span>
                </div>}
                </div>
              </div>
              
              <hr className="border-black-400 border-2" />
            </form>

            <form
              id={TABS[4]}
              onSubmit={(event) => {
                event.preventDefault();
                transferNFT();
                set_button_status((prev: any) => ({
                  ...prev,
                  nft: true,
                }));
              }}
              className="m-bottom-56"
            >
              <div className="m-bottom-32">
                <div className="m-bottom-24">
                  <h2 className="h-xl text-black-700 m-bottom-4">
                    Transfer NFT
                  </h2>
                  <p className="body-xs text-black-500">
                    Transferring the NFTs will not affect anything. The same NFT
                    will be transferred to the receiver's end, and a token will
                    also be transferred.
                  </p>
                </div>

                <div
                  className={`${classes.inputContainer} flex align-start col-gap-32 m-bottom-20`}
                >
                  <TokenAddressInput
                    label="Collection Address"
                    changeHandler={setTransferCollectionAddress}
                    name="Collection Address"
                    id="Collection Address"
                    placeholder="Your ERC-404 collection address"
                  />
                </div>

                <div
                  className={`${classes.inputContainer} flex align-start col-gap-32 m-bottom-20`}
                >
                  <TokenAddressInput
                    label="Receiver Address"
                    changeHandler={setTransferReceiverAddress}
                    name="Receiver Address"
                    id="Receiver Address"
                    placeholder="Wallet Address"
                  />
                  <IdInput
                    label="Token Id"
                    changeHandler={setTransferTokenId}
                    name="Token Id"
                    id="Token Id"
                    placeholder="Token Id"
                    errorMsg="Token Id must be a number"
                  />
                </div>
                <div className={classes.btn_row}>
                {active ? (
                  <button
                    disabled={
                      button_status.collection ||
                      button_status.uploadIPFS ||
                      button_status.whitelist ||
                      button_status.token ||
                      button_status.nft
                    }
                    className={`${classes.btn} btn btn-primary width-full`}
                  >
                    {button_status.nft ? (
                      <span className={classes.loading}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-loader"
                        >
                          <line x1="12" y1="2" x2="12" y2="6"></line>
                          <line x1="12" y1="18" x2="12" y2="22"></line>
                          <line x1="4.93" y1="4.93" x2="7.76" y2="7.76"></line>
                          <line
                            x1="16.24"
                            y1="16.24"
                            x2="19.07"
                            y2="19.07"
                          ></line>
                          <line x1="2" y1="12" x2="6" y2="12"></line>
                          <line x1="18" y1="12" x2="22" y2="12"></line>
                          <line
                            x1="4.93"
                            y1="19.07"
                            x2="7.76"
                            y2="16.24"
                          ></line>
                          <line
                            x1="16.24"
                            y1="7.76"
                            x2="19.07"
                            y2="4.93"
                          ></line>
                        </svg>
                      </span>
                    ) : (
                      <span>Confirm Transfer</span>
                    )}
                  </button>
                ) : (
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      login();
                    }}
                    className={`${classes.btn} btn btn-primary width-full`}
                  >
                    Connect Wallet
                  </button>
                )}
                {transactionHash.nftHash &&
                <div className={classes.succeed}>
                  <span>Transfered <img src={checked}/></span>
                  <span>Great! Receiver can view NFTs on 
                    <a href={chainId===80001?`https://testnets.opensea.io/`:`https://opensea.io/`}  target='_blank' rel='noreferrer'>Opensea</a>
                    & 
                    <a href={chainId===80001?`https://testnet.rarible.com/`:`https://rarible.com/`} target='_blank' rel='noreferrer'>Rarible</a>
                  </span>               
                </div>}
                </div>
              </div>
              
              <hr className="border-black-400 border-2" />
            </form>
          </ScrollSpy>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Erc404;
