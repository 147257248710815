/**
 * Types
 */
export type NFT = {
  id: number;
  name: string;
  media: string;
  collection: string;
  status: Status;
};
export type Option = {
  value: string;
  clickHandler: (text: string) => void;
};

export type ColoredDropdownType = {
  options: Array<Option>;
  title: string;
};

export type KeyValueComponentProps = {
  keyChange: (value: StringType, index: number) => void;
  valueChange: (value: StringType, index: number) => void;
  deleteItem: (index: number) => void;
  index?: number;
};

export type KeyValueChangeHandler = (values: TupleType[]) => void;
export type StringType = string | number | readonly string[] | undefined;
export type FileType = File | null | undefined;
export type TupleType = [StringType, StringType];

/**
 * Enumerations
 */
export enum Status {
  minted = 'Minted',
  listed = 'Listed',
  'drop minted' = 'Drop minted',
  'on auction' = 'On auction',
}

export enum MessageStatus {
  'Error' = 'error',
  'Success' = 'success',
  'Process' = 'process',
}

export enum ModalForms {
  'Sell' = 'sell',
  'Auction' = 'auction',
  'Bid' = 'bid',
}
