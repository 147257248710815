import { MessageStatus } from '../../types';

type Props = {
  message: string;
  status: MessageStatus;
};

const Message = (props: Props) => {
  const { message, status } = props;

  let bg: string;

  switch (status) {
    case MessageStatus.Process:
      bg = 'bg-skyblue-200';
      break;
    case MessageStatus.Success:
      bg = 'bg-green-200';
      break;
    case MessageStatus.Error:
      bg = 'bg-red-200';
      break;
    default:
      bg = '';
      break;
  }

  return (
    <div
      className='position-fixed width-full flex justify-left align-center'
      style={{
        zIndex: '1000',
        top: 0,
        paddingTop: '95px',
        justifyContent: 'right',
      }}>
      <div className={`width-max-content p-20 ${bg} text-black-100`}>
        {message}
      </div>
    </div>
  );
};

export default Message;
