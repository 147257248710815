import { useEffect, useState } from 'react';

import DappCard from '../../components/DappCard';
import NFTRow from '../../components/NFTRow';
import Footer from '../../components/Footer';
import classes from './Dapps.module.css';

import erc404gif from '../../animation/erc404.json'
import minting from '../../images/minting.svg';
import dropdapp from '../../images/dropdapp.svg';
import marketplace from '../../images/marketplace.svg';
import collection from '../../images/collection.svg';
import emptySearch from '../../images/empty-search.mp4';
import menu from '../../images/menu.svg';
import { useUser } from '../../context';

// const TABS = ['smart contracts', 'dashboard'];
const TABS= ["smart contracts"]
const CARDS = [
  {
    // logo: "https://app.lottiefiles.com/share/aeaa56b4-09d8-4021-9cbe-14c1fb100827",
    animation: erc404gif,
    title: 'Explore ERC-404',
    desc: 'Mint ERC-20 & ERC-721 collectively',
    to: '/demo/erc404',
    href: 'https://docs.expressprotocol.io/guide',
    btn: 'Try it',
  },
  // {
  //   logo: minting,
  //   title: 'NFT Minting',
  //   desc: 'On-deck Tool to Mint & Publish NFTs',
  //   to: '/demo/minting',
  //   href: 'https://docs.expressprotocol.io/guide',
  //   btn: 'Try it',
  // },
  // {
  //   logo: dropdapp,
  //   title: 'NFT Drop',
  //   desc: 'Drop Exclusive NFT Collectibles',
  //   to: '/demo/dropdapp',
  //   href: 'https://docs.expressprotocol.io/guide2',
  //   btn: 'Try it',
  // },
  // {
  //   logo: marketplace,
  //   title: 'NFT Marketplace',
  //   desc: 'Customize NFT Marketplace',
  //   to: '/demo/marketplace',
  //   href: 'https://docs.expressprotocol.io/guide',
  //   btn: 'Try it',
  // },
  // {
  //   logo: collection,
  //   title: 'NFT Collection',
  //   desc: 'Create, Mint Personalized NFT Collection',
  //   to: '/demo/collection',
  //   href: 'https://docs.expressprotocol.io/guide',
  //   btn: 'Try it',
  // },
];

const Dapps = () => {
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<string>(TABS[0]);
  const [toggle, setToggle] = useState<boolean>(false);
  const [collectionFilter, setCollectionFilter] = useState<string>('');

  const { nfts, collections } = useUser();

  const changeTab = (tab: string) => {
    setActiveTab(tab);
  };

  const setFilter = (filter: string) => {
    if (filter === 'All') {
      setCollectionFilter('');
      setToggle(false);
      return;
    }
    setCollectionFilter(filter);
    setToggle(false);
  };

  let allCollections = collections && [...collections];
  if (allCollections) {
    allCollections.unshift({ name: 'All', address: '' });
  }

  let filteredNFTs = nfts && [...nfts];
  if (collectionFilter !== '') {
    filteredNFTs = nfts?.filter((nft) => nft.tokenName === collectionFilter);
  }

  useEffect(() => {
    if (window.innerWidth < 650) {
      setIsMobile(true);
    }
  }, [window.innerWidth]);

  return (
    <>
      <div
        className={`${classes.container} ${
          isMobile ? 'm-top-16' : 'flex m-top-56'
        }`}>
        <div
          className={`${classes.tabsContainer} ${
            isMobile && 'border-bottom border-black-300'
          }`}>
          {TABS.map((tab) => (
            <button
              key={tab}
              onClick={() => changeTab(tab)}
              className={`${classes.tabBtn} ${
                activeTab === tab ? `${classes.active}` : 'body-xs'
              } ${
                isMobile
                  ? 'p-x-24 p-y-16 body-s'
                  : 'm-bottom-24 p-x-30 p-y-8 body-xs'
              }`}>
              <span className='display-block width-max-content'>{tab}</span>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
                stroke='currentColor'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
                className='feather feather-chevron-right'>
                <polyline points='9 18 15 12 9 6'></polyline>
              </svg>
            </button>
          ))}
        </div>
        {activeTab === TABS[0] && (
          <div className={`${classes.col2} width-1 p-x-30 p-bottom-30`}>
            <div
              className={`flex flex-wrap ${
                isMobile && 'justify-center'
              } gap-24`}>
              {CARDS.map((card) => (
                <DappCard
                  key={`${card.title}${card.desc}${card.to}${card.href}`}
                  {...card}
                />
              ))}
            </div>
          </div>
        )}
        {activeTab === TABS[1] && (
          <>
            <div
              className={`${classes.col2} m-bottom-30 width-full ${
                !isMobile && 'p-left-32'
              }`}>
              <div className={classes.dataContainer}>
                <table className={classes.table}>
                  <thead className='border-bottom border-2 border-purple-200'>
                    <tr className={`bg-purple-100`}>
                      <th className='p-16 text-black-600'>TokenID</th>
                      <th className='p-16 text-black-600'>Media</th>
                      <th className='p-16 text-black-600'>Name</th>
                      <th className='position-relative flex justify-center p-16 text-black-600'>
                        <button
                          onClick={() => setToggle((oldToggle) => !oldToggle)}
                          style={{ background: 'transparent' }}
                          className='border-none text-black-600 flex align-center col-gap-8'>
                          <span>Collection</span>
                          <img src={menu} alt='filter the table' />
                        </button>
                        {toggle && filteredNFTs && filteredNFTs?.length > 0 && (
                          <ul
                            className={`${classes.filters} position-absolute bg-black-100 shadow-bottom border-radius-8 p-x-0 p-y-12`}>
                            {allCollections?.map((collection) => (
                              <li key={collection.name + collection.address}>
                                <button
                                  onClick={() => setFilter(collection.name)}
                                  className={`${classes.filter} display-block width-full border-1 border-black-400 bg-black-100 p-x-12 p-y-8`}>
                                  {collection.name}
                                </button>
                              </li>
                            ))}
                          </ul>
                        )}
                      </th>
                      <th className='p-16 text-black-600'>Status</th>
                      <th className='p-16 text-black-600'>Actions</th>
                    </tr>
                  </thead>
                  {filteredNFTs && filteredNFTs.length > 0 && (
                    <tbody>
                      {filteredNFTs?.map((nft) => (
                        <NFTRow key={nft.tokenId} {...nft} />
                      ))}
                    </tbody>
                  )}
                </table>
                {filteredNFTs && filteredNFTs.length < 1 && (
                  <div className='flex flex-col justify-center align-center'>
                    <video
                      muted
                      loop
                      autoPlay
                      width='300px'
                      height='300px'
                      className='m-bottom-8'>
                      <source src={emptySearch} type='video/mp4' />
                    </video>
                    <h1 className='h-xl text-black-600 m-bottom-8'>
                      No NFT Data Available!
                    </h1>
                    <p className='body-m text-black-500'>
                      Click on{' '}
                      <button
                        onClick={() => changeTab(TABS[0])}
                        className='border-none bg-black-100 text-black-500'>
                        “Smart Contracts”
                      </button>{' '}
                      to Proceed Further
                    </p>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </div>
      <Footer styles={activeTab === TABS[0] ? classes.footer : ''} />
    </>
  );
};

export default Dapps;
