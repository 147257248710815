import { Link } from 'react-router-dom';
import Lottie from "react-lottie";

type Props = {
  animation?: any;
  logo?: string;
  title: string;
  desc: string;
  to: string;
  href: string;
  btn: string;
};

const DappCard = (props: Props): JSX.Element => {
  const { animation, logo, title, desc, to, href, btn } = props;

  return (
    <div
      style={{ width: '320px' }}
      className='bg-black-200 border-radius-8 p-30 shadow-around'>
        {animation?
        <div style={{height: '50px', width:"112px", marginBottom: "26px"}}>
        <Lottie options={{
          loop: true,
          autoplay: true,
          animationData: props.animation,
          rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
          },
        }}
        />
        </div>
        :<img src={logo} alt={title} className='m-bottom-30' />

        }
      <h2 className='h-m text-black-600 m-bottom-8'>{title}</h2>
      <p className='body-xs text-black-500 m-bottom-30'>{desc}</p>
      <div className='flex justify-space-between align-center'>
        <Link to={to} className='btn btn-primary'>
          {btn}
        </Link>
        <a
          href={href}
          target='_blank'
          rel='noreferrer'
          className='btn btn-tertiary'>
          <span>Learn More</span>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
            stroke='currentColor'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
            className='feather feather-chevron-right'>
            <polyline points='9 18 15 12 9 6'></polyline>
          </svg>
        </a>
      </div>
    </div>
  );
};

export default DappCard;
