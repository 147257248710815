import ColoredDropdown from '../ColoredDropdown';
import { MessageStatus, Option, Status } from '../../types';
import Moralis from 'moralis/types';
import { useMessage, useModal, useUser, useWallet } from '../../context';
import SellFormModal from '../SellFormModal';
import AuctionFormModal from '../AuctionFormModal';
import BidFormModal from '../BidFormModal';
import TransferModal from '../TransferModal';
import { saveBurn, saveCancel } from '../../helper';

const NFTRow = (props: Moralis.Attributes): JSX.Element => {
  const { tokenId, metadata, name, tokenName, status, saleId, tokenAddress } =
    props;

  const modal = useModal();
  const message = useMessage();
  const { pandora, getLatestNfts } = useUser();
  const { chainId, account, library } = useWallet();

  let options: Array<Option> = [];

  const cancel = async () => {
    let transaction;
    try {
      message('Calling cancel smart contract', MessageStatus.Process);
      transaction = await pandora.erc721.order.cancelSale(
        library,
        chainId,
        account,
        saleId
      );
    } catch (error) {
      message(
        'Not able to call smart contract function. Please check your form data.',
        MessageStatus.Error,
        3000
      );
      console.log(error);
      return;
    }
    try {
      message('Saving all the data', MessageStatus.Process);
      const result = await saveCancel(transaction, chainId!, saleId);
      if (result) {
        message(
          'Successfully canceled your sale or auction',
          MessageStatus.Success,
          3000
        );
        getLatestNfts && getLatestNfts();
      } else {
        message('Not able to saved all data', MessageStatus.Error, 3000);
      }
    } catch (error) {
      message(
        'Something went wrong while saving all data',
        MessageStatus.Error,
        3000
      );
      console.log(error);
      return;
    }
  };

  const burn = async () => {
    let transaction;
    try {
      message('Calling burn smart contract', MessageStatus.Process);
      transaction = await pandora.erc721.collection.burn(
        library,
        tokenAddress,
        account,
        tokenId
      );
    } catch (error) {
      message(
        'Not able to call smart contract function. Please check your form data.',
        MessageStatus.Error,
        3000
      );
      console.log(error);
      return;
    }
    try {
      message('Saving all the data', MessageStatus.Process);
      const result = await saveBurn(transaction, chainId!, tokenId);
      if (result) {
        message('Successfully burnt your NFT', MessageStatus.Success, 3000);
        getLatestNfts && getLatestNfts();
      } else {
        message('Not able to saved all data', MessageStatus.Error, 3000);
      }
    } catch (error) {
      message(
        'Something went wrong while saving all data',
        MessageStatus.Error,
        3000
      );
      console.log(error);
      return;
    }
  };

  const clickHandler = (text: string) => {
    switch (text) {
      case 'Sell':
        modal(<SellFormModal nft={{ ...props }} />);
        break;
      case 'Auction':
        modal(<AuctionFormModal nft={{ ...props }} />);
        break;
      case 'Execute Bid':
        modal(<BidFormModal nft={{ ...props }} />);
        break;
      case 'Transfer':
        modal(<TransferModal nft={{ ...props }} />);
        break;
      case 'Burn':
        burn();
        break;
      case 'Cancel Sale':
      case 'Cancel Auction':
        cancel();
        break;
      default:
        break;
    }
  };

  switch (status) {
    case Status.minted:
      options = [
        {
          value: 'Sell',
          clickHandler: clickHandler,
        },
        {
          value: 'Auction',
          clickHandler: clickHandler,
        },
        {
          value: 'Burn',
          clickHandler: clickHandler,
        },
      ];
      break;
    case Status.listed:
      options = [
        {
          value: 'Cancel Sale',
          clickHandler: clickHandler,
        },
      ];
      break;
    case Status['on auction']:
      options = [
        {
          value: 'Cancel Auction',
          clickHandler: clickHandler,
        },
        {
          value: 'Execute Bid',
          clickHandler: clickHandler,
        },
      ];
      break;
    case Status['drop minted']:
      options = [
        {
          value: 'Transfer',
          clickHandler: clickHandler,
        },
        {
          value: 'Burn',
          clickHandler: clickHandler,
        },
      ];
      break;
    default:
      options = [];
      break;
  }

  return (
    <tr className='border-bottom border-black-400'>
      <td className='text-black-600 capitalize text-center'>{tokenId}</td>
      <td className='p-y-16 flex justify-center'>
        <img
          style={{ width: '70px', height: '70px', objectFit: 'cover' }}
          className='border-radius-8'
          src={metadata.image}
          alt={name}
        />
      </td>
      <td className='text-black-600 capitalize text-center'>{name}</td>
      <td className='text-black-600 capitalize text-center'>{tokenName}</td>
      <td className='text-black-600 capitalize text-center'>{status}</td>
      <td>
        <div className='width-full height-full flex justify-center'>
          <ColoredDropdown options={options} title='Actions' />
        </div>
      </td>
    </tr>
  );
};

export default NFTRow;
