import { useState } from 'react';
import { useWallet } from '../../context';

import Binance from '../../images/binance.svg';
import Ethereum from '../../images/ethereum.svg';
import Polygon from '../../images/polygon.svg';

const Networks: {
  [networkId: number]: {
    image: string;
    name: string;
  };
} = {
  3: { image: Ethereum, name: 'ETH' },
  4: { image: Ethereum, name: 'ETH' },
  56: { image: Binance, name: 'BNB' },
  97: { image: Binance, name: 'BNB' },
  137: { image: Polygon, name: 'MATIC' },
  80001: { image: Polygon, name: 'MATIC' },
};

type Props = {
  setPrice: (value: string) => void;
  name: string;
  id: string;
  placeholder?: string;
};

const PriceInput = (props: Props) => {
  const { setPrice, name, id, placeholder } = props;

  const { chainId } = useWallet();

  const [priceError, setPriceError] = useState<boolean>(false);

  return (
    <>
      <div className='position-relative display-block width-full '>
        <input
          onBlur={(event) => {
            let value = event.target.value;
            if (value === null || value === '' || value === undefined) {
              priceError && setPriceError(false);
              return;
            }
            if (value.match(/[^\d\.]+/g)) {
              setPriceError(true);
              return;
            } else {
              setPriceError(false);
            }
            setPrice(value);
          }}
          className='display-block width-full border-none border-radius-8 bg-black-250 p-y-8 p-x-24 body-s text-black-600'
          type='text'
          name={name}
          id={id}
          placeholder={placeholder}
        />
        {Networks[chainId!] && (
          <span
            style={{ top: '1px', right: '1px', borderRadius: '0 8px 8px 0' }}
            className='position-absolute bg-black-100 border border-black-250 p-y-8 p-x-24 body-xs text-black-500 font-weight-normal'>
            {Networks[chainId!].name}
          </span>
        )}
      </div>
      {priceError && (
        <span className='display-block body-2xs text-red-200 m-top-4'>
          Price must be a number
        </span>
      )}
    </>
  );
};

export default PriceInput;
