import classes from "./Footer.module.css";

import pandora from "../../images/Pandora_Dark.svg";
import heart from "../../images/heart.png";

const Footer = ({ styles }: { styles?: string }) => {
  return (
    <footer className={`${classes.footer} ${styles}`}>
      <div className={classes.copyright}>
        <p>&copy; 2022 Pandorapro LLC, All Rights Reserved</p>
        <a
          className="m-right-24"
          href="https://docs.google.com/document/d/e/2PACX-1vQGgyp5Yhy9U47sKUvpecLUoCSzLxYxWqkraQP7SrCAqrq1cTr-GDqd1EaMTMKTooodE8VzbLNNm6y9/pub"
          target="_blank"
          rel="noreferrer"
        >
          Privacy Policy
        </a>
        <a
          href="https://docs.google.com/document/d/e/2PACX-1vQv96z186gUqrstq275IH644uEj_GjOdlaSMPhOOOD1iG4ovfjkmzUqKbnsF5nVWnq6eVaDCak-CGgL/pub"
          target="_blank"
          rel="noreferrer"
        >
          Terms of Use
        </a>
      </div>
      <div className={classes.love}>
        Made with <img src={heart} alt="love" /> By{" "}
        <img src={pandora} alt="Pandora Finance" />
      </div>
    </footer>
  );
};

export default Footer;
