import { useState } from 'react';
import { ColoredDropdownType, Option } from '../../types';

import classes from './ColoredDropdown.module.css';

const ColoredDropdown = (props: ColoredDropdownType): JSX.Element => {
  const { options, title } = props;
  const [toggle, setToggle] = useState<Boolean>(false);

  return (
    <div className='width-max-content position-relative'>
      <div className={`${classes.value} flex align-center`}>
        <div className='p-y-4 p-x-16 bg-blue-200 text-black-100 body-xs'>
          Actions
        </div>
        <button
          onClick={() => setToggle(!toggle)}
          className='flex justify-center align-center border-none bg-black-250 text-black-500'>
          {toggle ? (
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'
              stroke='currentColor'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
              className='feather feather-chevron-up'>
              <polyline points='18 15 12 9 6 15'></polyline>
            </svg>
          ) : (
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'
              stroke='currentColor'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
              className='feather feather-chevron-down'>
              <polyline points='6 9 12 15 18 9'></polyline>
            </svg>
          )}
        </button>
      </div>
      {toggle && (
        <div
          className={`${classes.options} position-absolute width-full bg-black-100 border border-black-400 border-radius-4 shadow-around`}>
          <div className='p-8 h-2xs text-black-500 capitalize'>{title}</div>
          <ul className='p-0 m-0'>
            {options.map((option: Option) => (
              <li
                key={option.value}
                onClick={() => {
                  option.clickHandler(option.value);
                  setToggle(false);
                }}
                className='p-x-16 p-y-8'>
                <button className='border-none bg-black-100 body-2xs text-black-500'>
                  {option.value}
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default ColoredDropdown;
