import { FormEventHandler, useRef } from 'react';
import { useMessage, useUser, useWallet } from '../../context';
import { saveExecuteBid } from '../../helper';
import { MessageStatus } from '../../types';

const BidFormModal = ({ nft }: { nft: any }) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const { saleId, bids } = nft;

  const { pandora, getLatestNfts } = useUser();
  const { chainId, account, library } = useWallet();
  const message = useMessage();

  const handleSumbit: FormEventHandler = async (event) => {
    event.preventDefault();

    if (inputRef.current === null) return;

    let bidId: string = inputRef.current.value;
    try {
      message('Calling execute bid smart contract', MessageStatus.Process);
      await pandora.erc721.order.acceptBid(
        library,
        chainId,
        saleId,
        bidId,
        account
      );
    } catch (error) {
      message(
        'Not able to call smart contract function. Please check your form data.',
        MessageStatus.Error,
        3000
      );
      console.log(error);
      return;
    }
    try {
      message('Saving all the data', MessageStatus.Process);
      const result = await saveExecuteBid(chainId!, saleId, bidId);
      if (result) {
        message(
          'Successfully have executed the bid',
          MessageStatus.Success,
          3000
        );
        getLatestNfts && getLatestNfts();
      } else {
        message('Not able to saved all data', MessageStatus.Error, 3000);
      }
    } catch (error) {
      message(
        'Something went wrong while saving all data',
        MessageStatus.Error,
        3000
      );
      console.log(error);
      return;
    }
  };

  return (
    <form onSubmit={handleSumbit} style={{width:"300px", marginTop:"20px"}}>
      <h2 className='text-center h-s m-bottom-16'>Execute Bid for the NFT</h2>
      <h3 className='text-black-500 h-xs m-bottom-8'>Bids</h3>
      {bids && bids.length && (
        <ul
          style={{ height: '200px', overflowY: 'auto' }}
          className='bg-black-400 p-12 m-bottom-20'>
          {bids.map((bid: any, index: number) => (
            <li
              key={bid.bidId}
              className={`border-bottom p-bottom-8 ${
                index !== bids.length - 1 && 'm-bottom-12'
              } text-black-600 h-2xs`}>
              <div>
                <span className='body-2xs'>Bid ID:</span> {bid.bidId}
              </div>
              <div>
                <span className='body-2xs'>Bid Amt:</span>{' '}
                {library?.utils.fromWei(bid.price)}
              </div>
            </li>
          ))}
        </ul>
      )}
      <div className='flex flex-col align-start row-gap-8 m-bottom-12'>
        <label htmlFor='bid id'>Bid ID</label>
        <input
          ref={inputRef}
          className='display-block width-full border-radius-4 border-none p-y-4 p-x-12 bg-black-300 text-black-600 body-s'
          type='number'
          name='bid id'
          id='bid id'
          placeholder='Eg. 23'
          required
          aria-required={true}
        />
      </div>
      <button className='btn btn-primary width-full'>Execute Bid</button>
    </form>
  );
};

export default BidFormModal;
