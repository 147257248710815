import { FormEventHandler, useState } from 'react';
import { useMessage, useUser, useWallet } from '../../context';
import { saveTransfer } from '../../helper';
import { MessageStatus } from '../../types';
import TokenAddressInput from '../TokenAddressInput';

const TransferModal = ({ nft }: { nft: any }) => {
  const [toAddress, setToAddress] = useState<string>('');

  const { tokenId, tokenAddress } = nft;

  const { pandora, getLatestNfts } = useUser();
  const { chainId, account, library } = useWallet();
  const message = useMessage();

  const handleSubmit: FormEventHandler = async (event) => {
    event.preventDefault();

    if (toAddress === '') return;

    try {
      message('Calling transfar smart contract', MessageStatus.Process);
      await pandora.erc721.collection.transferNFT(
        library,
        chainId,
        tokenAddress,
        tokenId,
        account,
        toAddress
      );
    } catch (error) {
      message(
        'Not able to call smart contract function. Please check your form data.',
        MessageStatus.Error,
        3000
      );
      console.log(error);
      return;
    }
    try {
      message('Saving all the data', MessageStatus.Process);
      let result = await saveTransfer(chainId!, account!, toAddress, tokenId);
      if (result) {
        message(
          'Successfully transfered your NFT',
          MessageStatus.Success,
          3000
        );
        getLatestNfts();
      } else {
        message('Not able to saved all data', MessageStatus.Error, 3000);
      }
    } catch (error) {
      message(
        'Something went wrong while saving all data',
        MessageStatus.Error,
        3000
      );
      console.log(error);
      return;
    }
  };

  return (
    <form onSubmit={handleSubmit} style={{width:"300px", marginTop:"20px"}}>
      <h2 className='text-center h-s m-bottom-16'>Transfer Your NFT</h2>
      <div className='m-bottom-12'>
        <TokenAddressInput
          changeHandler={(value) => setToAddress(value)}
          label='To Address'
          name='To address'
          id='To address'
          placeholder='0x9181aE86DCF651406c0Ca352d1dFa8efe98A9635'
        />
      </div>
      <button className='btn btn-primary width-full'>Transfer</button>
    </form>
  );
};

export default TransferModal;
