import { useMessage, useWallet } from '../../context';

import Binance from '../../images/binance.svg';
import Polygon from '../../images/polygon.svg';
import Ethereum from '../../images/ethereum.svg';
import Logout from '../../images/log-out.svg';
import Copy from '../../images/clipboard.svg';
import { useEffect, useState } from 'react';

import classes from './Chains.module.css';
import { MessageStatus } from '../../types';
import { copyToClipboard } from '../../helper';
import { ethers } from 'ethers';

type Network = {
  id: number;
  name: string;
  image: string;
  decimals?: number;
  symbol?: string;
  rpcURLs?: string[];
};

const Networks: Network[] = [
  // {
  //   id: 3,
  //   name: 'Ropsten (Testnet)',
  //   image: Ethereum,
  //   decimals: 18,
  //   symbol: 'ETH',
  //   rpcURLs: ['https://ropsten.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161'],
  // },
  // {
  //   id: 4,
  //   name: 'Rinkeby (Testnet)',
  //   image: Ethereum,
  //   decimals: 18,
  //   symbol: 'ETH',
  //   rpcURLs: ['https://rinkeby.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161'],
  // },
  // {
  //   id: 56,
  //   name: 'Binance Mainnet',
  //   image: Binance,
  //   decimals: 18,
  //   symbol: 'BNB',
  //   rpcURLs: ['https://bsc-dataseed.binance.org/'],
  // },
  // {
  //   id: 97,
  //   name: 'Binance (Testnet)',
  //   image: Binance,
  //   decimals: 18,
  //   symbol: 'BNB',
  //   rpcURLs: ['https://data-seed-prebsc-1-s1.binance.org:8545/'],
  // },
  {
    id: 1,
    name: 'Ethereum Mainnet',
    image: Ethereum,
    decimals: 18,
    symbol: 'ETH',
    rpcURLs: ['https://mainnet.infura.io/v3/'],
  },
  {
    id: 137,
    name: 'Polygon Mainnet',
    image: Polygon,
    decimals: 18,
    symbol: 'MATIC',
    rpcURLs: ['https://polygon-rpc.com/'],
  },
  // {
  //   id: 80001,
  //   name: 'Polygon (Mumbai)',
  //   image: Polygon,
  //   decimals: 18,
  //   symbol: 'MATIC',
  //   rpcURLs: ['https://rpc-mumbai.maticvigil.com/'],
  // },
];

const Chains = (): JSX.Element => {
  const message = useMessage();

  const [toggle, setToggle] = useState(false);
  const [network, setNetwork] = useState<Network>();

  const { provider, chainId, active, logout, account, dnsName } = useWallet();

  // const switchNetwork = async (selectedNetwork: Network) => {
  //   try {
  //     await library.provider?.request({
  //       method: 'wallet_switchEthereumChain',
  //       params: [{ chainId: `0x${selectedNetwork.id.toString(16)}` }],
  //     });
  //   } catch (switchError: any) {
  //     if (switchError.code === 4902) {
  //       try {
  //         await library.provider.request({
  //           method: 'wallet_addEthereumChain',
  //           params: [
  //             {
  //               chainId: `0x${selectedNetwork.id.toString(16)}`,
  //               rpcUrls: selectedNetwork.rpcURLs,
  //               chainName: selectedNetwork.name,
  //               nativeCurrency: {
  //                 name: selectedNetwork.symbol,
  //                 decimals: selectedNetwork.decimals,
  //                 symbol: selectedNetwork.symbol,
  //               },
  //             },
  //           ],
  //         });
  //       } catch (error) {
  //         console.error(error);
  //       }
  //     }
  //   }
  // };

  const switchNetwork = async (selectedNetwork: Network) => {
    try {
      await provider?.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: `0x${selectedNetwork.id.toString(16)}` }],
      });
    } catch (switchError: any) {
      if (switchError.code === 4902) {
        try {
          await provider.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainId: `0x${selectedNetwork.id.toString(16)}`,
                chainName: selectedNetwork.name,
                rpcUrls: selectedNetwork.rpcURLs,
                nativeCurrency: {
                  name: selectedNetwork.symbol,
                  decimals: selectedNetwork.decimals,
                  symbol: selectedNetwork.symbol,
                },
              },
            ],
          });
        } catch (addError) {
          throw addError;
        }
      }
    }
  };

  const getNetwork = (chain: number): Network | undefined =>
    Networks.find((network) => network.id === chain);

  useEffect(() => {
    if (active && chainId) {
      let currentNetwork = getNetwork(chainId);
      if (currentNetwork) {
        if (currentNetwork != network) {
          setNetwork(currentNetwork);
        }
      } else {
        setNetwork(currentNetwork);
        message(
          'This blockchain network is not supported yet! Please change to the supported network.',
          MessageStatus.Error,
          3000
        );
      }
    }
  }, [chainId]);

  const clickHandler = async (selectedNetwork: Network) => {
    if (network == selectedNetwork) {
      setToggle(false);
      return;
    }
    try {
      switchNetwork(selectedNetwork);
    } finally {
      setToggle(false);
    }
  };
  console.log(dnsName)

  return (
    <div className='position-relative'>
      <div
        onClick={(e) => {
          e.preventDefault();
          setToggle(!toggle);
        }}
        className={`width-max-content flex col-gap-8 align-center ${
          network ? 'bg-black-250 text-black-600' : 'bg-red-200 text-black-100'
        } border-radius-8 body-xs`}
        style={{ padding: '10px 24px' }}>
        {network ? (
          <div className='flex align-center col-gap-16'>
            <img
              className={classes.icon}
              src={network?.image}
              alt={network?.name}
            />
            <div className='flex flex-col body-2xs'>
              <span>{dnsName? dnsName: account?.slice(0, 10)+"..."}</span>
              {/* <span>{ account?.slice(0, 10)+"..."}</span> */}
              <span>{network?.name}</span>
            </div>
          </div>
        ) : (
          <span>Wrong Network</span>
        )}
        <button
          aria-label='Toggle dropdown'
          className={`${classes.dropdownBtn} p-0 border-none ${
            network
              ? 'bg-black-250 text-black-600'
              : 'bg-red-200 text-black-100'
          } justify-center align-center`}>
          {toggle ? (
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'
              stroke='currentColor'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
              className='feather feather-chevron-up'>
              <polyline points='18 15 12 9 6 15'></polyline>
            </svg>
          ) : (
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'
              stroke='currentColor'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
              className='feather feather-chevron-down'>
              <polyline points='6 9 12 15 18 9'></polyline>
            </svg>
          )}
        </button>
      </div>
      {toggle && (
        <ul
          className={`${classes.list} p-0 m-0 position-absolute bg-black-100 border-radius-4 shadow-bottom`}>
          <li key='copy-address'>
            <button
              onClick={() => {
                copyToClipboard(account || '');
                message('Copied your address', MessageStatus.Success, 1500);
                setToggle(false);
              }}
              className={`${classes.button} width-full border-none bg-black-300 text-black-600 flex align-center col-gap-4 p-8`}>
              <img src={Copy} alt='Copy Address' />
              <span>Copy wallet address</span>
            </button>
          </li>
          {Networks.map((network: Network) => (
            <li key={network.id}>
              <button
                onClick={() => clickHandler(network)}
                className={`${classes.button} width-full border-none bg-black-100 text-black-600 flex align-center col-gap-4 p-8`}>
                <img
                  className={classes.icon}
                  src={network.image}
                  alt={network.name}
                />
                <span>{network.name}</span>
              </button>
            </li>
          ))}
          <li key='disconnect'>
            <button
              onClick={() => {
                logout();
                localStorage.clear();
              }}
              className={`${classes.button} width-full border-none bg-black-300 text-black-600 flex align-center col-gap-4 p-8`}>
              <img src={Logout} alt='Disconnect' />
              <span>Disconnect</span>
            </button>
          </li>
        </ul>
      )}
    </div>
  );
};

export default Chains;
