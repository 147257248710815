import Moralis from 'moralis/node';
import { Status } from '../types';

export const saveMint = async (result: any, nft: any) => {
  try {
    let NFT = Moralis.Object.extend('NFT');
    let newNft = new NFT();

    newNft.set('name', nft.name);
    newNft.set('chain', nft.chainId);
    newNft.set('tokenURI', nft.uri);
    newNft.set('metadata', nft.metadata);
    newNft.set('tokenName', nft.collection);
    newNft.set('tokenType', 'ERC721');
    newNft.set('amount', '1');
    newNft.set(
      'status',
      nft.status === 'Drop Minted' ? Status['drop minted'] : Status.minted
    );
    newNft.set('transactionHash', result.transactionHash);
    newNft.set('tokenAddress', result.to.toLowerCase());
    newNft.set('tokenId', result.events.Transfer.returnValues.tokenId);
    newNft.set('creatorAddress', result.from);
    newNft.set('ownerAddress', result.from);
    newNft.set('price', '');
    newNft.set('saleId', '');
    newNft.set('bidSale', false);
    newNft.set('directSale', false);
    newNft.set('bidStartTime', '');
    newNft.set('bidEndTime', '');
    newNft.set('bids', []);

    await newNft.save();

    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const saveBuy = async (result: any, chain: number, saleId: string) => {
  try {
    const NFT = Moralis.Object.extend('NFT');
    const query = new Moralis.Query(NFT);
    query.equalTo('chain', chain);
    query.equalTo('saleId', saleId);
    const nft = await query.first();
    console.log(nft);

    if (nft) {
      nft.set('status', Status.minted);
      nft.set('price', '');
      nft.set('saleId', '');
      nft.set('directSale', false);
      nft.set('ownerAddress', result.from);

      await nft.save();

      return true;
    } else {
      console.log('There is no such nft with sale id: ' + saleId);
      return false;
    }
  } catch (error) {
    console.log(error);
    console.log('Not able to save sell data');
    return false;
  }
};

export const saveSaleAuction = async (result: any, chain: number) => {
  try {
    const NFT = Moralis.Object.extend('NFT');
    const query = new Moralis.Query(NFT);
    query.equalTo('chain', chain);
    query.equalTo('tokenId', result.tokenId);
    query.equalTo('tokenAddress', result.collectionAddress.toLowerCase());
    const nft = await query.first();

    if (nft) {
      nft.set('status', result.bidSale ? Status['on auction'] : Status.listed);
      nft.set('price', result.price);
      nft.set('saleId', result.saleId);
      nft.set('bidSale', result.bidSale);
      nft.set('directSale', result.directSale);
      nft.set('bidStartTime', result.bidStartTime);
      nft.set('bidEndTime', result.bidEndTime);

      await nft.save();

      return true;
    } else {
      console.log('There is no such nft with id: ' + result.tokenId);
      return false;
    }
  } catch (error) {
    console.log(error);
    console.log('Not able to save sell data');
    return false;
  }
};

export const saveBid = async (result: any, chain: number, saleId: string) => {
  try {
    const NFT = Moralis.Object.extend('NFT');
    const query = new Moralis.Query(NFT);
    query.equalTo('chain', chain);
    query.equalTo('saleId', saleId);
    query.equalTo('ownerAddress', result.sellerAddress.toLowerCase());
    const nft = await query.first();

    if (nft) {
      const bids = nft.get('bids') || [];
      bids.push({
        ...result,
      });
      nft.set('bids', bids);

      await nft.save();

      return true;
    } else {
      console.log('There is no such nft with sale id: ' + saleId);
      return false;
    }
  } catch (error) {
    console.log(error);
    console.log('Not able to save sell data');
    return false;
  }
};

export const saveExecuteBid = async (
  chain: number,
  saleId: string,
  bidId: string
) => {
  try {
    const NFT = Moralis.Object.extend('NFT');
    const query = new Moralis.Query(NFT);
    query.equalTo('chain', chain);
    query.equalTo('saleId', saleId);
    const nft = await query.first();

    if (nft) {
      let bids: Array<any> = nft.get('bids') || [];
      let bid = bids.find((bid) => bid.bidId === bidId);
      bids = bids.filter((bid) => bid.bidId !== bidId);

      if (bids.length > 0) {
        nft.set('bids', bids);
      } else {
        nft.set('bids', []);
        nft.set('saleId', '');
      }

      nft.set('status', Status.minted);
      nft.set('price', '');
      nft.set('bidSale', false);
      nft.set('bidStartTime', '');
      nft.set('bidEndTime', '');
      nft.set('ownerAddress', bid.buyerAddress.toLowerCase());

      await nft.save();

      return true;
    } else {
      console.log('There is no such nft with sale id: ' + saleId);
      return false;
    }
  } catch (error) {
    console.log(error);
    console.log('Not able to save sell data');
    return false;
  }
};

export const saveWithdrawBid = async (
  chain: number,
  saleId: string,
  bidId: string
) => {
  try {
    const NFT = Moralis.Object.extend('NFT');
    const query = new Moralis.Query(NFT);
    query.equalTo('chain', chain);
    query.equalTo('saleId', saleId);
    const nft = await query.first();

    if (nft) {
      let bids: Array<any> = nft.get('bids') || [];
      bids = bids.filter((bid) => bid.bidId !== bidId);

      if (bids.length > 0) {
        nft.set('bids', bids);
      } else {
        let bidSale = nft.get('bidSale');
        nft.set('bids', []);
        if (!bidSale) nft.set('saleId', '');
      }

      await nft.save();

      return true;
    } else {
      console.log('There is no such nft with sale id: ' + saleId);
      return false;
    }
  } catch (error) {
    console.log(error);
    console.log('Not able to save sell data');
    return false;
  }
};

export const saveCancel = async (
  result: any,
  chain: number,
  saleId: string
) => {
  try {
    const NFT = Moralis.Object.extend('NFT');
    const query = new Moralis.Query(NFT);
    query.equalTo('chain', chain);
    query.equalTo('saleId', saleId);
    query.equalTo('tokenAddress', result.events[0].address.toLowerCase());
    const nft = await query.first();

    if (nft) {
      let bidSale = nft.get('bidSale');
      let bids = nft.get('bids');

      console.log(bidSale, bids);

      nft.set('status', Status.minted);
      nft.set('price', '');
      if (bidSale) {
        if (bids.length < 1) {
          nft.set('saleId', '');
        }
      } else {
        nft.set('saleId', '');
      }
      nft.set('directSale', false);
      nft.set('bidSale', false);
      nft.set('bidStartTime', '0');
      nft.set('bidEndTime', '0');

      await nft.save();

      return true;
    } else {
      console.log('There is no such nft with sale id: ' + saleId);
      return false;
    }
  } catch (error) {
    console.log(error);
    console.log('Not able to save sell data');
    return false;
  }
};

export const saveBurn = async (result: any, chain: number, tokenId: string) => {
  try {
    const NFT = Moralis.Object.extend('NFT');
    const query = new Moralis.Query(NFT);
    query.equalTo('chain', chain);
    query.equalTo('tokenId', tokenId);
    query.equalTo('ownerAddress', result.from);
    query.equalTo('tokenAddress', result.to);
    const nft = await query.first();

    if (nft) {
      await nft.destroy();

      return true;
    } else {
      console.log('There is no such nft with token id: ' + tokenId);
      return false;
    }
  } catch (error) {
    console.log(error);
    console.log('Not able to save sell data');
    return false;
  }
};

export const saveTransfer = async (
  chain: number,
  fromAddress: string,
  toAddress: string,
  tokenId: string
) => {
  try {
    const NFT = Moralis.Object.extend('NFT');
    const query = new Moralis.Query(NFT);
    query.equalTo('chain', chain);
    query.equalTo('ownerAddress', fromAddress.toLowerCase());
    query.equalTo('tokenId', tokenId);
    query.equalTo('status', Status['drop minted']);
    const nft = await query.first();

    if (nft) {
      nft.set('status', Status.minted);
      nft.set('ownerAddress', toAddress.toLowerCase());

      await nft.save();

      return true;
    } else {
      console.log('There is no such nft with token id: ' + tokenId);
      return false;
    }
  } catch (error) {
    console.log(error);
    console.log('Not able to save sell data');
    return false;
  }
};

export const getAllNFTs = async (chain: number) => {
  try {
    const NFT = Moralis.Object.extend('NFT');
    const query = new Moralis.Query(NFT);
    query.equalTo('chain', chain);
    query.notEqualTo('status', Status['drop minted']);
    const result = await query.find();
    const nfts = result.map((nft) => nft.attributes);
    return nfts;
  } catch (error) {
    console.log(error);
    console.log('Not able to fetch nfts.');
    return [];
  }
};

export const getAllMyNFTs = async (chain: number, address: string) => {
  try {
    const NFT = Moralis.Object.extend('NFT');
    const query = new Moralis.Query(NFT);
    query.equalTo('ownerAddress', address.toLowerCase());
    query.equalTo('chain', chain);
    const result = await query.find();
    const nfts = result.map((nft) => nft.attributes);
    return nfts;
  } catch (error) {
    console.log(error);
    console.log('Not able to fetch nfts.');
    return [];
  }
};
