import { useState } from 'react';
import Label from '../Label';

type Props = {
  changeHandler: (value: string) => void;
  label: string;
  name: string;
  id: string;
  placeholder?: string;
  errorMsg?: string;
};

const IdInput = (props: Props) => {
  const { changeHandler, label, name, id, placeholder, errorMsg } = props;

  const [error, setError] = useState<boolean>(false);

  return (
    <div>
      <Label htmlFor={id} label={label} />
      <input
        onBlur={(event) => {
          let value = event.target.value;
          if (value.match(/\D+/gi)) {
            setError(true);
            return;
          } else {
            setError(false);
          }
          changeHandler(event.target.value);
        }}
        className={`display-block width-full ${
          error ? 'border border-red-200' : 'border-none'
        } border-radius-8 bg-black-250 p-y-8 p-x-24 body-s text-black-600`}
        type='number'
        name={name}
        id={id}
        placeholder={placeholder}
      />
      {error && (
        <span className='display-block body-2xs text-red-200 m-top-4'>
          {errorMsg || 'ID has to be a number.'}
        </span>
      )}
    </div>
  );
};

export default IdInput;
